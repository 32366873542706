<template>
    <section class="multirow-form">
        <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
             class="columns is-variable is-1">
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Date')" class="column padding">
                <g-datepicker v-model="entry.date" name="date" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Time')" class="column padding">
                <g-timepicker v-model="entry.time" name="time" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Target Person')" class="column padding">
                <b-input v-model="entry.target_person" name="target_person" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Symptoms')" class="column padding">
                <b-input v-model="entry.symptoms" name="symptoms" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Measures')" class="column padding">
                <b-input v-model="entry.measures" name="measures" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Person in charge')" class="column padding">
                <b-input v-model="entry.person_in_charge" name="person_in_charge" />
            </b-field>
            <div class="column is-narrow">
                <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                        :title="$t('Delete entry')" @click="removeEntry(index)">
                    <b-icon icon="delete" size="default" />
                </button>
            </div>
        </div>
        <b-button class="is-info" icon-left="plus" @click="addEntryRow">
            {{ $t('Add entry row') }}
        </b-button>
    </section>
</template>

<script>
    import { uuidv4 } from '@/utils'

    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'DiseaseReportSheet',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
