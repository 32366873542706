<template>
    <div>
        <section>
            <b-field :label="$t('Should Temperature')" expanded>
                <b-input v-model="form.should_temperature" name="should_temperature" />
            </b-field>
            <b-field :label="$t('Storage Location')" expanded>
                <b-input v-model="form.storage_location" name="storage_location"
                         :readonly="!!form.related_name"
                         :size="!!form.related_name ? 'is-static' : ''" />
            </b-field>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Date')" class="column padding">
                    <g-datepicker v-model="entry.date" name="date" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Is Temperature') " class="column padding">
                    <b-input v-model="entry.is_temperature" name="is_temperature" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Is Temperature Re-Measure') " class="column padding">
                    <b-input v-model="entry.is_temperature_re_measurement"
                             name="is_temperature_re_measurement" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Comment or Measure') " class="column padding">
                    <b-input v-model="entry.comment_or_measure" name="comment_or_measure" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Visa') " class="column padding">
                    <b-input v-model="entry.visa" name="visa" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger"
                            style="margin-bottom: .35em"
                            :title="$t('Delete entry')">
                        <DeleteIcon @click="removeEntry(index)" />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'StorageTemperatureCheck',
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            if (this.form.related_name)
                this.form.storage_location = this.form.related_name
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
