<template>
    <div>
        <b-field :label="$t('Date')">
            <g-datepicker v-model="form.date" name="date" />
        </b-field>
        <b-field :label="$t('Location')">
            <b-input v-model="form.location" name="location" />
        </b-field>
        <section>
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="entry">
                <hr>
                <b-field :label="$t('Meal')">
                    <b-input v-model="entry.description" name="description" />
                </b-field>
                <div v-for="(m, mIndex) in entry.measures" :key="mIndex" class="columns multirow-form">
                    <b-field class="column padding"
                             :custom-class="mIndex === 0 ? '' : 'is-hidden-desktop'"
                             :label="$t('Time')">
                        <g-timepicker v-model="m.time" name="time" duration />
                    </b-field>
                    <b-field class="column padding"
                             :custom-class="mIndex === 0 ? '' : 'is-hidden-desktop'"
                             :label="$t('Temperature')">
                        <b-input v-model="m.temperature" type="number" name="temperature" />
                    </b-field>
                    <b-field class="column padding"
                             :custom-class="mIndex === 0 ? '' : 'is-hidden-desktop'"
                             :label="$t('Measure')">
                        <b-input v-model="m.measure" name="measure" />
                    </b-field>
                    <div class="column is-narrow">
                        <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                                :title="$t('Delete entry')">
                            <DeleteIcon @click="removeMeasure(entry, mIndex)" />
                        </button>
                    </div>
                </div>
                <div class="buttons">
                    <b-button type="is-info" icon-left="plus"
                              size="is-small"
                              @click="addMeasureRow(entry)">
                        {{ $t('Add a measure') }}
                    </b-button>
                    <b-button type="is-danger" icon-left="minus"
                              size="is-small"
                              @click="removeEntry(index)">
                        {{ $t('Remove entry') }}
                    </b-button>
                </div>
            </div>
            <div class="buttons">
                <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                    {{ $t('Add entry row') }}
                </b-button>
            </div>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'

    class Entry {
        constructor(data = { measures: [] }) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)

            if(this.measures.length === 0) {
                this.measures.push({ time: null })
                this.measures.push({ time: null })
                this.measures.push({ time: null })
            }
        }
    }

    export default {
        name: 'CoreTemperatureCheck',
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const form = this.value || {}
                    if (!form.entries)
                        form.entries = [
                            new Entry(),
                            new Entry(),
                            new Entry()
                        ]
                    return form
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            },
            addMeasureRow(entry) {
                entry.measures.push({ time: null })
                this.$forceUpdate()
            },
            removeMeasure(entry, measureIndex) {
                entry.measures.splice(measureIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .entry {
        padding-bottom: 1rem;
    }
</style>
