<template>
    <section>
        <section v-for="(section, index) in sections" :key="index">
            <h3>{{ section.title }}</h3>
            <div class="columns">
                <h4 class="column">{{ i18n.t('Danger') }}</h4>
                <h4 class="column">{{ i18n.t('Cause') }}</h4>
                <h4 class="column">{{ i18n.t('Measure') }}</h4>
            </div>
            <div v-for="(row, idx) in section.rows" :key="idx" class="columns">
                <div class="column"><span v-html="row.descriptions.danger" /></div>
                <div class="column"><span v-html="row.descriptions.cause" /></div>
                <div class="column"><span v-html="row.descriptions.measure" /></div>
                <div v-for="(meta, key, i) in row.fields" :key="i" class="column"
                     :label="meta.label">
                    <b-field v-if="meta.component == 'b-select'"
                             :label="idx===0 ? $t('Documentation'): null">
                        <component :is="meta.component" v-model="form[key]" v-bind="meta.attrs">
                            <option
                                v-for="option in meta.choices"
                                :key="option.id"
                                :value="option.id">
                                {{ $t(option.label) }}
                            </option>
                        </component>
                    </b-field>
                    <b-field v-else :label="idx===0 ? $t('Implemented'): null">
                        <component :is="meta.component" v-model="form[key]" v-bind="meta.attrs" />
                    </b-field>
                </div>
            </div>
            <br>
        </section>
    </section>
</template>

<script>
    import i18n from '@/config/i18n'

    export default {
        name: 'HazardAnalysis',
        props: { value: { type: Object, required: true } },
        data() {
            return {
                choices: [
                    { id: 'AVAIL', label: this.$t('Available') },
                    { id: 'VERB', label: this.$t('Verbally') },
                    { id: 'MISS', label: this.$t('Missing') }
                ],
                i18n: i18n
            }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            sections() {
                return [
                    {
                        title: this.$t('HazardAnalysis.deliver.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.deliver.reproduction_contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.deliver.reproduction_contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.deliver.reproduction_contamination_microorganisms.measure')
                                },
                                fields: {
                                    deliver_reproduction_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    deliver_reproduction_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.deliver.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.deliver.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.deliver.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    deliver_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    deliver_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.stock.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.stock.reproduction_contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.stock.reproduction_contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.stock.reproduction_contamination_microorganisms.measure')
                                },
                                fields: {
                                    stock_reproduction_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    stock_reproduction_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.stock.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.stock.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.stock.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    stock_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    stock_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.stock.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.stock.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.stock.contamination_allergenes.measure')
                                },
                                fields: {
                                    stock_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    stock_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.prepare.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.prepare.reproduction_contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.prepare.reproduction_contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.prepare.reproduction_contamination_microorganisms.measure')
                                },
                                fields: {
                                    prepare_reproduction_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    prepare_reproduction_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.prepare.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.prepare.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.prepare.contamination_allergenes.measure')
                                },
                                fields: {
                                    prepare_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    prepare_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.prepare.contamination_cleaningagents_disinfectants.danger'),
                                    cause: this.$t('HazardAnalysis.prepare.contamination_cleaningagents_disinfectants.cause'),
                                    measure: this.$t('HazardAnalysis.prepare.contamination_cleaningagents_disinfectants.measure')
                                },
                                fields: {
                                    prepare_contamination_cleaningagents_disinfectants_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    prepare_contamination_cleaningagents_disinfectants_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.prepare.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.prepare.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.prepare.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    prepare_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    prepare_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.cold_preparation.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cold_preparation.reproduction_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.cold_preparation.reproduction_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.cold_preparation.reproduction_microorganisms.measure')
                                },
                                fields: {
                                    cold_preparation_reproduction_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cold_preparation_reproduction_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cold_preparation.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.cold_preparation.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.cold_preparation.contamination_allergenes.measure')
                                },
                                fields: {
                                    cold_preparation_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cold_preparation_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cold_preparation.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.cold_preparation.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.cold_preparation.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    cold_preparation_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cold_preparation_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cold_preparation.contamination_cleaningagents_disinfectants.danger'),
                                    cause: this.$t('HazardAnalysis.cold_preparation.contamination_cleaningagents_disinfectants.cause'),
                                    measure: this.$t('HazardAnalysis.cold_preparation.contamination_cleaningagents_disinfectants.measure')
                                },
                                fields: {
                                    cold_preparation_contamination_cleaningagents_disinfectants_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cold_preparation_contamination_cleaningagents_disinfectants_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.warm_preparation.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.warm_preparation.survival_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.warm_preparation.survival_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.warm_preparation.survival_microorganisms.measure')
                                },
                                fields: {
                                    warm_preparation_survival_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    warm_preparation_survival_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.warm_preparation.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.warm_preparation.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.warm_preparation.contamination_allergenes.measure')
                                },
                                fields: {
                                    warm_preparation_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    warm_preparation_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.warm_preparation.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.warm_preparation.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.warm_preparation.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    warm_preparation_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    warm_preparation_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.warm_preparation.harmful_substances.danger'),
                                    cause: this.$t('HazardAnalysis.warm_preparation.harmful_substances.cause'),
                                    measure: this.$t('HazardAnalysis.warm_preparation.harmful_substances.measure')
                                },
                                fields: {
                                    warm_preparation_harmful_substances_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    warm_preparation_harmful_substances_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.warm_preparation.contamination_cleaningagents_disinfectants.danger'),
                                    cause: this.$t('HazardAnalysis.warm_preparation.contamination_cleaningagents_disinfectants.cause'),
                                    measure: this.$t('HazardAnalysis.warm_preparation.contamination_cleaningagents_disinfectants.measure')
                                },
                                fields: {
                                    warm_preparation_contamination_cleaningagents_disinfectants_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    warm_preparation_contamination_cleaningagents_disinfectants_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.keep_warm.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.keep_warm.recontamination_unwanted_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.keep_warm.recontamination_unwanted_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.keep_warm.recontamination_unwanted_microorganisms.measure')
                                },
                                fields: {
                                    keep_warm_recontamination_unwanted_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    keep_warm_recontamination_unwanted_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.keep_warm.reproduction_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.keep_warm.reproduction_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.keep_warm.reproduction_microorganisms.measure')
                                },
                                fields: {
                                    keep_warm_reproduction_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    keep_warm_reproduction_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.cool.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cool.reproduction_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.cool.reproduction_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.cool.reproduction_microorganisms.measure')
                                },
                                fields: {
                                    cool_reproduction_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cool_reproduction_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.cool.contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.cool.contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.cool.contamination_microorganisms.measure')
                                },
                                fields: {
                                    cool_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    cool_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.keep_cool.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.keep_cool.reproduction_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.keep_cool.reproduction_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.keep_cool.reproduction_microorganisms.measure')
                                },
                                fields: {
                                    keep_cool_reproduction_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    keep_cool_reproduction_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.keep_cool.contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.keep_cool.contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.keep_cool.contamination_microorganisms.measure')
                                },
                                fields: {
                                    keep_cool_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    keep_cool_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.defrost.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.defrost.contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.defrost.contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.defrost.contamination_microorganisms.measure')
                                },
                                fields: {
                                    defrost_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    defrost_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.defrost.growth_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.defrost.growth_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.defrost.growth_microorganisms.measure')
                                },
                                fields: {
                                    defrost_growth_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    defrost_growth_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.transport.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.transport.reproduction_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.transport.reproduction_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.transport.reproduction_microorganisms.measure')
                                },
                                fields: {
                                    transport_reproduction_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    transport_reproduction_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.transport.contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.transport.contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.transport.contamination_microorganisms.measure')
                                },
                                fields: {
                                    transport_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    transport_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.transport.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.transport.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.transport.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    transport_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    transport_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.transport.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.transport.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.transport.contamination_allergenes.measure')
                                },
                                fields: {
                                    transport_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    transport_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    },
                    {
                        title: this.$t('HazardAnalysis.sell_serve.title'),
                        rows: [
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.sell_serve.growth_contamination_microorganisms.danger'),
                                    cause: this.$t('HazardAnalysis.sell_serve.growth_contamination_microorganisms.cause'),
                                    measure: this.$t('HazardAnalysis.sell_serve.growth_contamination_microorganisms.measure')
                                },
                                fields: {
                                    sell_serve_growth_contamination_microorganisms_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    sell_serve_growth_contamination_microorganisms_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.sell_serve.contamination_foreign_bodies.danger'),
                                    cause: this.$t('HazardAnalysis.sell_serve.contamination_foreign_bodies.cause'),
                                    measure: this.$t('HazardAnalysis.sell_serve.contamination_foreign_bodies.measure')
                                },
                                fields: {
                                    sell_serve_contamination_foreign_bodies_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    sell_serve_contamination_foreign_bodies_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            },
                            {
                                descriptions: {
                                    danger: this.$t('HazardAnalysis.sell_serve.contamination_allergenes.danger'),
                                    cause: this.$t('HazardAnalysis.sell_serve.contamination_allergenes.cause'),
                                    measure: this.$t('HazardAnalysis.sell_serve.contamination_allergenes.measure')
                                },
                                fields: {
                                    sell_serve_contamination_allergenes_is_implemented: {
                                        label: this.$t('Something'),
                                        component: 'b-switch'
                                    },
                                    sell_serve_contamination_allergenes_documentation: {
                                        label: this.$t('Something'),
                                        component: 'b-select',
                                        choices: this.choices
                                    }
                                }
                            }
                        ]
                    }
                ]
            }
        }
    }
</script>
