<template>
    <b-modal v-if="active" :active="true" has-modal-card
             trap-focus
             :can-cancel="false" @close="close(false)">
        <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <div class="modal-card-title">{{ $t('Archive empty form?') }}</div>
            </header>
            <section class="modal-card-body">
                <div class="columns">
                    <div class="column is-narrow">
                        <b-icon icon="alert-circle" size="is-large" class="has-text-danger" />
                    </div>
                    <div class="column" style="align-items: center; display: flex">
                        {{ message }}
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <div class="buttons" style="justify-content: flex-end; width:100%;">
                    <b-button class="is-white" @click.prevent="close(false)">
                        {{ $t('Cancel') }}
                    </b-button>
                    <b-button type="is-primary" @click.prevent="close(true)">
                        {{ $t('Archive anyway') }}
                    </b-button>
                </div>
            </footer>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'ConfirmEmptyFormArchival',
        data() {
            return {
                active: false,
                resolve: null,

                // eslint-disable-next-line max-len
                message: this.$t('You are about to save and archive an empty form, if you need a blank template it would be better to download the form directly from the form list.')
            }
        },
        methods: {
            show() {
                return new Promise(resolve => {
                    this.resolve = resolve
                    this.active = true
                })
            },
            close(returnValue) {
                this.$emit('close', returnValue)
                this.active = false
                this.resolve && this.resolve(returnValue)
                this.resolve = null
            }
        }
    }
</script>
