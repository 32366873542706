<template>
    <section class="multirow-form">
        <b-field :label="$t('Responsible')">
            <b-input v-model="form.responsible" name="responsible" />
        </b-field>
        <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
             class="columns is-variable is-1">
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Date')" class="column padding">
                <g-datepicker v-model="entry.date" name="date" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Theme')" class="column padding">
                <b-input v-model="entry.theme" name="theme" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Goal')" class="column padding">
                <b-input v-model="entry.goal" name="goal" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Review')" class="column padding">
                <b-input v-model="entry.review" name="review" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Speaker')" class="column padding">
                <b-input v-model="entry.speaker" name="speaker" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Duration')" class="column padding">
                <g-timepicker v-model="entry.duration" name="duration" duration />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Participants')" class="column padding">
                <b-input v-model="entry.participants" name="participants" type="textarea" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Delete')" class="column is-narrow">
                <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                        :title="$t('Delete entry')">
                    <DeleteIcon @click="removeEntry(index)" />
                </button>
            </b-field>
        </div>
        <b-button class="is-info" icon-left="plus" @click="addEntryRow">
            {{ $t('Add entry row') }}
        </b-button>
    </section>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'

    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>

<style scoped>
.multirow-form .columns .column {
    margin-top: 0;
}
</style>
