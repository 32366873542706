<template>
    <generic-form v-model="form" :fields="fields" />
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'
    import { mapState } from 'vuex'

    export default {
        name: 'JobDescription',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return {
                fields: {
                    lastname_and_firstname: {
                        label: this.$t('Name and Firstname'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    qualifications_in_food_safety: { label: this.$t('Qualification in food safety'), component: 'b-input' },
                    replacement_responsible_person: {
                        label: this.$t('Replacement responsible person'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    }
                }
            }
        },
        computed: {
            ...mapState('settings', [ 'employee' ]),
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            employee({ data }) {
                const names = data.map(d => d.lastname_and_firstname)
                this.fields.lastname_and_firstname.attrs.data = names
                this.fields.replacement_responsible_person.attrs.data = names
            }
        },
        mounted() {
            this.$store.dispatch('settings/fetchEmployee')
        }
    }
</script>
