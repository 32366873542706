<template>
    <div>
        <div class="switch-wrapper">
            <b-switch v-model="examinationIsOK" type="is-success">
                {{ description }}
                <span :class="[
                    { 'has-text-success': examinationIsOK, 'has-text-danger': !examinationIsOK },
                    'has-text-weight-bold'
                ]">
                    {{ isOkLabel }}
                </span>
            </b-switch>
        </div>

        <div v-show="!examinationIsOK" id="fields"
             class="columns column is-multiline no-vertical-padding">
            <b-field class="column" :label="$t('findings')">
                <b-input v-model="form[propertyName].findings" name="findings" />
            </b-field>
            <b-field class="column" :label="$t('actions')">
                <b-input v-model="form[propertyName].actions" name="actions" />
            </b-field>
            <b-field class="column" :label="$t('responsible')">
                <g-autocomplete v-model="form[propertyName].responsible"
                                name="responsible"
                                :data="employees"
                                open-on-focus
                />
            </b-field>
            <b-field class="column" :label="$t('to be done until')">
                <g-datepicker v-model="form[propertyName].to_be_done_until"
                              name="to_be_done_until" />
            </b-field>
            <b-field class="column" :label="$t('done at')">
                <g-datepicker v-model="form[propertyName].done_at" name="done_at" />
            </b-field>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'InfrastructureExaminationFields',
        props: {
            description: {
                type: String,
                required: true
            },
            value: {
                type: Object,
                required: true
            },
            propertyName: {
                type: String,
                required: true
            },
            employees: {
                type: Array,
                required: true
            }
        },
        data() {
            return { examinationIsOK: true }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            isOkLabel() {
                return this.examinationIsOK ? this.$t('is OK') : this.$t('is not OK')
            }
        },
        watch: {
            form: {
                deep: true,
                immediate: true,
                handler(f) {
                    if (!f[ this.propertyName ])
                        f[ this.propertyName ] = {}

                    if (!f[ this.propertyName ].id)
                        delete f[ this.propertyName ].id
                }
            },
            examinationIsOK(value) {
                if (value === false)
                    try {
                        delete this.form[ this.propertyName ].DELETE
                    } catch {
                        // nothing
                    }
                else
                    this.form[ this.propertyName ].DELETE = true
            }
        },
        mounted() {
            this.examinationIsOK = !(this.form[ this.propertyName ].findings
                || this.form[ this.propertyName ].actions
                || this.form[ this.propertyName ].responsible
                || this.form[ this.propertyName ].to_be_done_until
                || this.form[ this.propertyName ].done_at)
        }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars.scss";

.switch-wrapper {
    margin-bottom: 1rem;

    /deep/ .switch .check,
    /deep/ .switch:hover .check {
        background: $danger;
    }
}

#fields {
    padding-top: 0;
    padding-bottom: 2rem;

    .field:first-child {
        border-left: 3px solid $border;
    }
}
</style>
