<template>
    <div>
        <section>
            <b-field :label="$t('Date')" expanded>
                <g-datepicker v-model="form.date" name="date" />
            </b-field>
            <b-field :label="$t('Responsible for technical and general maintenance')" expanded>
                <g-autocomplete v-model="form.responsible_technical"
                                name="responsible_technical"
                                :data="employeesList"
                                open-on-focus
                />
            </b-field>
            <b-field :label="$t('Controlled by')" expanded>
                <g-autocomplete v-model="form.controlled_by"
                                name="controlled_by"
                                :data="employeesList"
                                open-on-focus
                />
            </b-field>
        </section>
        <section class="multirow-form">
            <section>
                <div class="section-title"><b>{{ $t('Delivery') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_lamps_clean')"
                                                   property-name="e_delivery_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_lamps_work')"
                                                   property-name="e_delivery_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_floor_clean')"
                                                   property-name="e_delivery_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_general_clean')"
                                                   property-name="e_delivery_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_drain_clean')"
                                                   property-name="e_delivery_drain_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_delivery_tools_intact')"
                                                   property-name="e_delivery_tools_intact"
                                                   :employees="employeesList" />
            </section>
            <section>
                <div class="section-title"><b>{{ $t('Unclean area') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_lamps_clean')"
                                                   property-name="e_unclean_area_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_lamps_work')"
                                                   property-name="e_unclean_area_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_walls_clean')"
                                                   property-name="e_unclean_area_walls_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_ventilation_clean')"
                                                   property-name="e_unclean_area_ventilation_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_general_clean')"
                                                   property-name="e_unclean_area_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_machines_work')"
                                                   property-name="e_unclean_area_machines_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_hand_wash_clean')"
                                                   property-name="e_unclean_area_hand_wash_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_salad_clean')"
                                                   property-name="e_unclean_area_salad_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_drain_clean')"
                                                   property-name="e_unclean_area_drain_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_first_aid_complete')"
                                                   property-name="e_unclean_area_first_aid_complete"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_vegetables_machine_clean')"
                                                   property-name="e_unclean_area_vegetables_machine_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_prep_table_clean')"
                                                   property-name="e_unclean_area_prep_table_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_sink_clean')"
                                                   property-name="e_unclean_area_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_cable_work')"
                                                   property-name="e_unclean_area_cable_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_unclean_area_no_pests')"
                                                   property-name="e_unclean_area_no_pests"
                                                   :employees="employeesList" />
            </section>
            <section>
                <div class="section-title"><b>{{ $t('Dry products storage') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_lamps_clean')"
                                                   property-name="e_dry_storage_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_lamps_work')"
                                                   property-name="e_dry_storage_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_walls_clean')"
                                                   property-name="e_dry_storage_walls_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_ventilation_clean')"
                                                   property-name="e_dry_storage_ventilation_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_general_clean')"
                                                   property-name="e_dry_storage_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_temperature_good')"
                                                   property-name="e_dry_storage_temperature_good"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_floor_clean')"
                                                   property-name="e_dry_storage_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_dry_storage_no_pests')"
                                                   property-name="e_dry_storage_no_pests"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('Employee wardrobe') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_lamps_clean')"
                                                   property-name="e_employee_wardrobe_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_lamps_work')"
                                                   property-name="e_employee_wardrobe_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_walls_clean')"
                                                   property-name="e_employee_wardrobe_walls_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_ventilation_clean')"
                                                   property-name="e_employee_wardrobe_ventilation_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_nothing_on_top')"
                                                   property-name="e_employee_wardrobe_nothing_on_top"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_clothes_stored')"
                                                   property-name="e_employee_wardrobe_clothes_stored"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_general_clean')"
                                                   property-name="e_employee_wardrobe_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_employee_wardrobe_hand_wash_clean')"
                                                   property-name="e_employee_wardrobe_hand_wash_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('cold room') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_lamps_clean')"
                                                   property-name="e_cold_storage_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_lamps_work')"
                                                   property-name="e_cold_storage_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_floor_clean')"
                                                   property-name="e_cold_storage_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_general_clean')"
                                                   property-name="e_cold_storage_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_cooling_unit_clean')"
                                                   property-name="e_cold_storage_cooling_unit_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_temperature_good')"
                                                   property-name="e_cold_storage_temperature_good"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_alarm_button_free')"
                                                   property-name="e_cold_storage_alarm_button_free"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_storage_door_clean')"
                                                   property-name="e_cold_storage_door_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('freezer') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_lamps_clean')"
                                                   property-name="e_freezer_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_lamps_work')"
                                                   property-name="e_freezer_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_walls_clean')"
                                                   property-name="e_freezer_walls_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_general_clean')"
                                                   property-name="e_freezer_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_no_ice')"
                                                   property-name="e_freezer_no_ice"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_alarm_button_free')"
                                                   property-name="e_freezer_alarm_button_free"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_door_clean')"
                                                   property-name="e_freezer_door_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_cooling_unit_clean')"
                                                   property-name="e_freezer_cooling_unit_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_freezer_temperature_good')"
                                                   property-name="e_freezer_temperature_good"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('cold kitchen') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_lamps_clean')"
                                                   property-name="e_cold_kitchen_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_lamps_work')"
                                                   property-name="e_cold_kitchen_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_floor_clean')"
                                                   property-name="e_cold_kitchen_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_general_clean')"
                                                   property-name="e_cold_kitchen_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_sink_clean')"
                                                   property-name="e_cold_kitchen_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_machines_clean')"
                                                   property-name="e_cold_kitchen_machines_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_stove_clean')"
                                                   property-name="e_cold_kitchen_stove_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_bathroom_sink_clean')"
                                                   property-name="e_cold_kitchen_bathroom_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_work_space_clean')"
                                                   property-name="e_cold_kitchen_work_space_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_drawers_clean')"
                                                   property-name="e_cold_kitchen_drawers_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_cold_kitchen_cables_work')"
                                                   property-name="e_cold_kitchen_cables_work"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('warm kitchen') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_lamps_work')"
                                                   property-name="e_warm_kitchen_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_lamps_clean')"
                                                   property-name="e_warm_kitchen_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_ventilation_clean')"
                                                   property-name="e_warm_kitchen_ventilation_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_walls_clean')"
                                                   property-name="e_warm_kitchen_walls_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_general_clean')"
                                                   property-name="e_warm_kitchen_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_sink_clean')"
                                                   property-name="e_warm_kitchen_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_storage_clean')"
                                                   property-name="e_warm_kitchen_storage_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_oven_clean')"
                                                   property-name="e_warm_kitchen_oven_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_bathroom_sink_clean')"
                                                   property-name="e_warm_kitchen_bathroom_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_stoves_clean')"
                                                   property-name="e_warm_kitchen_stoves_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_steamer_clean')"
                                                   property-name="e_warm_kitchen_steamer_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_closet_clean')"
                                                   property-name="e_warm_kitchen_closet_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_work_space_clean')"
                                                   property-name="e_warm_kitchen_work_space_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_drawers_clean')"
                                                   property-name="e_warm_kitchen_drawers_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_warm_kitchen_cables_work')"
                                                   property-name="e_warm_kitchen_cables_work"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('buffet') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_lamps_clean')"
                                                   property-name="e_buffet_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_lamps_work')"
                                                   property-name="e_buffet_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_floors_clean')"
                                                   property-name="e_buffet_floors_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_sink_clean')"
                                                   property-name="e_buffet_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_general_clean')"
                                                   property-name="e_buffet_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_drawers_clean')"
                                                   property-name="e_buffet_drawers_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_buffet_coffee_machine_clean')"
                                                   property-name="e_buffet_coffee_machine_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('washing kitchen') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_lamps_clean')"
                                                   property-name="e_washing_kitchen_lamps_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_lamps_work')"
                                                   property-name="e_washing_kitchen_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_floor_clean')"
                                                   property-name="e_washing_kitchen_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_sink_clean')"
                                                   property-name="e_washing_kitchen_sink_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_general_clean')"
                                                   property-name="e_washing_kitchen_general_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_racks_clean')"
                                                   property-name="e_washing_kitchen_racks_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_washing_kitchen_dish_washer_clean')"
                                                   property-name="e_washing_kitchen_dish_washer_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('mode of transport') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_transport_clean')"
                                                   property-name="e_transport_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_transport_chests_clean')"
                                                   property-name="e_transport_chests_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('banquet hall / restaurant') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_clean')"
                                                   property-name="e_banquet_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_no_spider')"
                                                   property-name="e_banquet_no_spider"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_ventilation_clean')"
                                                   property-name="e_banquet_ventilation_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_windows_clean')"
                                                   property-name="e_banquet_windows_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_door_clean')"
                                                   property-name="e_banquet_door_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_socket_clean')"
                                                   property-name="e_banquet_socket_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_lamps_work')"
                                                   property-name="e_banquet_lamps_work"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_heating_clean')"
                                                   property-name="e_banquet_heating_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_window_ledge_clean')"
                                                   property-name="e_banquet_window_ledge_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_floor_clean')"
                                                   property-name="e_banquet_floor_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_plants_clean')"
                                                   property-name="e_banquet_plants_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_banquet_curtains_clean')"
                                                   property-name="e_banquet_curtains_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('guest rooms') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_guest_room_sanitary_clean')"
                                                   property-name="e_guest_room_sanitary_clean"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_guest_room_light_works')"
                                                   property-name="e_guest_room_light_works"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_guest_room_escape_works')"
                                                   property-name="e_guest_room_escape_works"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_guest_room_public_showers_clean')"
                                                   property-name="e_guest_room_public_showers_clean"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('public shower and bathing') }}</b></div>
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_public_showers_works')"
                                                   property-name="e_public_showers_works"
                                                   :employees="employeesList" />
                <infrastructure-examination-fields v-model="form"
                                                   :description="$t('e_public_bathing_works')"
                                                   property-name="e_public_bathing_works"
                                                   :employees="employeesList" />
            </section>

            <section>
                <div class="section-title"><b>{{ $t('more premises') }}</b></div>
                <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                     class="columns is-variable is-1">
                    <infrastructure-examination-entry v-model="form" :index="index"
                                                      :employees="employeesList" />
                    <div class="column is-narrow">
                        <button class="button is-small is-inverted is-danger"
                                style="margin-bottom: .35em"
                                :title="$t('Delete entry')">
                            <DeleteIcon @click="removeEntry(index)" />
                        </button>
                    </div>
                </div>
                <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                    {{ $t('Add entry row') }}
                </b-button>
            </section>

            <section class="section-title">
                <b-field grouped>
                    <b-field :label="$t('general impression')" expanded>
                        <b-input v-model="form.general_impression_title"
                                 name="general_impression_title" />
                    </b-field>
                    <b-field expanded :label="$t('general impression comment')">
                        <b-input v-model="form.general_impression_description"
                                 name="general_impression_description" />
                    </b-field>
                </b-field>
            </section>
        </section>

        <b-modal :active.sync="isSavePopupActive" has-modal-card trap-focus
                 :can-cancel="false" @close="closeSavePopup(false)">
            <div class="modal-card" style="width: auto">
                <header class="modal-card-head">
                    <div class="modal-card-title">{{ $t('Send notifications?') }}</div>
                </header>
                <section class="modal-card-body">
                    <div class="columns">
                        <div class="column is-narrow">
                            <b-icon icon="alert-circle" size="is-large" class="has-text-danger" />
                        </div>
                        <div class="column" style="align-items: center; display: flex">
                            {{ $t('Should notifications for uncompleted tasks be sent?') }}
                        </div>
                    </div>
                    <div v-if="saveAction === 'saveAndArchive'" class="columns"
                         style="border-top: 1px solid #eee; width: 95%; margin: 0 auto -1rem;">
                        <div class="column is-narrow" style="align-items: center; display: flex">
                            <b-icon icon="information-outline" class="has-text-info" />
                        </div>
                        <div class="column"
                             style="align-items: center; display: flex; font-size: 0.9rem; font-style: italic; max-width: 90%">
                            {{ $t('Note: Open tasks will be copied over to the new form.') }}
                        </div>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <div class="buttons" style="justify-content: flex-end; width:100%;">
                        <b-button class="is-white" @click.prevent="closeSavePopup(false)">
                            {{ $t('No') }}
                        </b-button>
                        <b-button type="is-primary" @click.prevent="closeSavePopup(true)">
                            {{ $t('Yes') }}
                        </b-button>
                    </div>
                </footer>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'
    import InfrastructureExaminationFields from '../components/InfrastructureExaminationFields'
    import InfrastructureExaminationEntry from '../components/InfrastructureExaminationEntry'
    import { mapState } from 'vuex'
    import cloneDeep from 'lodash/cloneDeep'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'InfrastructureExamination',
        components: {
            DeleteIcon,
            InfrastructureExaminationFields,
            InfrastructureExaminationEntry
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return { isSavePopupActive: false, savePopupPromise: null, saveAction: 'save' }
        },
        computed: {
            ...mapState('settings', [ 'employee' ]),
            employeesList() {
                return this.employee.data
                    .filter(e => e.email)
                    .map(e => e.lastname_and_firstname)
            },
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted: function () {
            this.$store.dispatch('settings/fetchEmployee')
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            },
            closeSavePopup(value) {
                this.isSavePopupActive = false
                this.savePopupPromise(value)
            },
            async presave({ action = 'save' }) {
                const hasAtLeastOneResponsiblePerson = Object.entries(this.form).some(([ key, value ]) => {
                    if (key === 'entries')
                        return value.some(entry => Boolean(entry.responsible))
                    else if (key.indexOf('e_') !== 0)
                        return false
                    return Boolean(value.responsible)
                })

                if (!hasAtLeastOneResponsiblePerson)
                    return Promise.resolve(false)

                return new Promise(resolve => {
                    this.isSavePopupActive = true
                    this.saveAction = action
                    this.savePopupPromise = resolve
                })
            },
            prepareDataForBackend(data) {
                const prepared = cloneDeep(data)
                Object.keys(prepared).forEach(key => {
                    if (key.indexOf('e_') !== 0 || prepared[ key ].DELETE)
                        return

                    if (examinationIsOk(key, prepared) === true && !prepared[ key ].id)
                        prepared[ key ] = null
                })
                return prepared
            }
        }
    }

    const examinationIsOk = (propertyName, data) => !(
        data[ propertyName ].findings
        || data[ propertyName ].actions
        || data[ propertyName ].responsible
        || data[ propertyName ].to_be_done_until
        || data[ propertyName ].done_at
    )
</script>

<style lang="scss" scoped>
.section-title {
    margin-top: 1rem;
    margin-bottom: 1rem;
}
</style>

