<template>
    <div>
        <section class="multirow-form">
            <h3>{{ $t('Overview Cleaning Products') }}</h3>
            <i18n tag="b-message" path="cleaning-agents.info" has-icon type="is-info">
                <router-link :to="{name: 'settings.list', hash: '#cleaning_agent'}">
                    {{ $t('settings-link') }}
                </router-link>
            </i18n>
            <b-table :data="form.detergent_entries">
                <template slot-scope="props">
                    <b-table-column field="product" :label="$t('Product')">
                        <template slot="header" slot-scope="{ column }">
                            {{ $t(column.label) }}
                        </template>
                        {{ props.row.product }}
                    </b-table-column>

                    <b-table-column field="area_of_application" :label="$t('Area of Application')">
                        <template slot="header" slot-scope="{ column }">
                            {{ $t(column.label) }}
                        </template>
                        {{ props.row.area_of_application }}
                    </b-table-column>

                    <b-table-column field="dosage" :label="$t('Dosage')">
                        <template slot="header" slot-scope="{ column }">
                            {{ $t(column.label) }}
                        </template>
                        {{ props.row.dosage }}
                    </b-table-column>
                </template>
            </b-table>
            <hr>
        </section>
        <section class="multirow-form">
            <h3>{{ $t('Inventory') }}</h3>
            <div v-for="(entry, index) in form.kitchen_entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Target')" class="column padding">
                    <g-autocomplete v-model="entry.target"
                                    name="target"
                                    :data="targetList"
                                    open-on-focus
                                    @change="updateRelatedFields(entry)"
                    />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Product')" class="column padding">
                    <b-input v-model="entry.product"
                             name="product"
                             :readonly="true"
                    />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Dosage')" class="column padding">
                    <b-input v-model="entry.dosage"
                             name="dosage"
                             :readonly="true"
                    />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Tool')" class="column padding">
                    <b-input v-model="entry.tool" name="tool" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Method of Application')"
                         class="column padding">
                    <b-input v-model="entry.method_of_application" name="method_of_application" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Frequency')" class="column padding">
                    <b-input v-model="entry.frequency" name="frequency" :readonly="true" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger"
                            style="margin-bottom: .35em"
                            :title="$t('Delete entry')" @click="removeKitchenEntry(index)">
                        <DeleteIcon />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addKitchenEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
            <hr>
        </section>
        <section class="multirow-form">
            <h3>{{ $t('Color concept for cleaning tools') }}</h3>
            <i18n tag="b-message" path="cleaning-color-concept.info" has-icon type="is-info">
                <router-link :to="{name: 'settings.list', hash: '#cleaning_color_concept'}">
                    {{ $t('settings-link') }}
                </router-link>
            </i18n>
            <b-table :data="form.color_concept_entries">
                <template slot-scope="props">
                    <b-table-column field="area" :label="$t('Area')">
                        <template slot="header" slot-scope="{ column }">
                            {{ $t(column.label) }}
                        </template>
                        {{ props.row.area }}
                    </b-table-column>
                    <b-table-column field="color" :label="$t('Color')">
                        <template slot="header" slot-scope="{ column }">
                            {{ $t(column.label) }}
                        </template>
                        {{ props.row.color }}
                    </b-table-column>
                </template>
            </b-table>
            <hr>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'
    import { getIntervalLabelByKey } from '../../Settings/const'
    import { mapState } from 'vuex'

    class Entry {
        constructor() {
            this.$uuid = uuidv4()
        }
    }

    export default {
        name: 'CleaningConcept',
        components: { DeleteIcon },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapState('settings', [ 'inventory', 'cleaningAgent' ]),
            targetList() {
                return this.inventory.data.filter(
                    source => !this.form.kitchen_entries.find(entry => entry.target === source.name)
                ).map(d => d.name)
            },
            form: {
                get() {
                    const form = this.value || {}

                    if (form.kitchen_entries && form.kitchen_entries.length > 0)
                        form.kitchen_entries = form.kitchen_entries.filter(
                            entry => !!this.inventory.data.find(source => source.name === entry.target)
                        ).map(
                            entry => {
                                const inventoryItem = this.inventory.data.find(source => source.name === entry.target)

                                entry.frequency = getIntervalLabelByKey(inventoryItem.cleaning_interval)
                                entry.product = inventoryItem.cleaning_agent
                                entry.dosage = inventoryItem.dosage

                                return entry
                            }
                        )
                    else
                        form.kitchen_entries = [
                            new Entry(),
                            new Entry(),
                            new Entry()
                        ]

                    return form
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            this.$store.dispatch('settings/fetchInventory')
            this.$store.dispatch('settings/fetchCleaningAgent')
        },
        methods: {
            addKitchenEntryRow() {
                this.form.kitchen_entries.push(new Entry())
                this.$forceUpdate()
            },
            removeKitchenEntry(entryIndex) {
                this.form.kitchen_entries.splice(entryIndex, 1)
                this.$forceUpdate()
            },
            updateRelatedFields(entry) {
                const inventoryItem = this.inventory.data.find(
                    el => el.name === entry.target
                )

                if (inventoryItem) {
                    entry.frequency = getIntervalLabelByKey(inventoryItem.cleaning_interval)
                    entry.product = inventoryItem.cleaning_agent
                    entry.dosage = inventoryItem.dosage
                } else {
                    entry.frequency = ''
                    entry.product = ''
                    entry.dosage = ''
                }
                this.$forceUpdate()
            },
            dosageList(entry) {
                return this.cleaningAgent.data
                    .filter(d => d.dosage && d.product===entry.product)
                    .map(d => d.dosage)
            }
        }
    }
</script>
