<template>
    <div v-if="!loading" class="form-list">
        <div v-for="group of formGroups" :key="group.key" class="form-group">
            <h2>{{ group.label }}</h2>
            <div class="form-list__padding">
                <b-table :ref="group.key"
                         :data="group.categories"
                         :row-class="rowClass"
                         hoverable
                         detailed
                         :show-detail-icon="false"
                         detail-key="type"
                         @click="row => openFormDetailCard(row, row.default)">
                    <template slot-scope="props">
                        <b-table-column field="name" :label="$t('Name')">
                            <div class="link">{{ props.row.label }}</div>
                            <div v-show="props.row.hasSubtypes"
                                 class="icon toggle"
                                 @click.prevent.stop="toggle(group.key, props.row)">
                                <i :class="['mdi', isOpen(group.key, props.row) ? 'mdi-chevron-up' : 'mdi-chevron-down', 'mdi-24px']" />
                            </div>
                        </b-table-column>
                        <b-table-column
                            :label="$t('Download')"
                            centered
                            class="action"
                            header-class="action">
                            <div :class="getReminderClassList(props.row)"
                                 @click.prevent.stop="openFormDetailCard(props.row, props.row.default, false, 1)">
                                <ReminderIcon />
                                <span class="reminder-icon-info">{{ $t('Reminders for this form are set.') }}</span>
                            </div>
                            <a target="_blank" :href="props.row.pdf" @click.stop="">
                                <DownloadIcon />
                            </a>
                        </b-table-column>
                    </template>
                    <template slot="detail" slot-scope="props">
                        <ol>
                            <li v-for="subtype of props.row.subtypes"
                                :key="subtype.id"
                                @click.prevent.stop="openFormDetailCard(props.row, subtype, true)">
                                <div class="link">
                                    {{ subtype.related_name }}
                                </div>
                            </li>
                        </ol>
                    </template>
                </b-table>
            </div>
        </div>

        <b-modal
            :active="isFormDetailCardActive"
            :width="1400"
            :can-cancel="['outside', 'button', 'escape']"
            @close="checkClose"
        >
            <FormDetailCard
                v-if="selectedFormCategory"
                :form-category="selectedFormCategory"
                :form-data="selectedFormData"
                :form-is-subtype="selectedFormIsSubtype"
                :selected-tab-index="selectedTabIndex"
                @change="(value) => formHasChanged = value"
                @success="success"
            />
        </b-modal>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import FormDetailCard from './components/FormDetailCard'
    import DownloadIcon from '@/components/icons/Download'
    import ReminderIcon from '@/components/icons/Reminder'
    import { DiscardChangesConfirmationMixin } from '@/config/mixins'

    export default {
        components: { FormDetailCard, DownloadIcon, ReminderIcon },
        mixins: [ DiscardChangesConfirmationMixin ],
        data() {
            return {
                selectedFormCategory: null,
                selectedFormData: null,
                isFormDetailCardActive: false,
                selectedFormIsSubtype: false,
                selectedTabIndex: 0,
                formHasChanged: false
            }
        },
        computed: {
            ...mapState('forms', [ 'formCategories', 'loading' ]),
            formGroups() {
                const groups = []

                if (this.formCategories)
                    for (let formCategory of this.formCategories) {
                        const groupKey = formCategory.group[0]
                        const groupName = formCategory.group[1]
                        const groupPosition = formCategory.group[2]

                        if (!groups[groupPosition])
                            groups[groupPosition] = {
                                'key': groupKey,
                                'label': groupName,
                                'categories': []
                            }

                        formCategory['hasSubtypes'] = this.hasSubtypes(formCategory)
                        groups[groupPosition]['categories'].push(formCategory)
                    }

                for (let i = 0; i < groups.length; i++)
                    groups[i]['categories'].sort((a, b) => a.position - b.position)

                return groups
            }
        },
        methods: {
            ...mapActions('forms', [ 'fetch' ]),
            success() {
                this.isFormDetailCardActive = false
                this.fetch({ subtypes: true, reminders: true })
            },
            checkClose() {
                if (this.formHasChanged)
                    this.confirmDiscardingChanges(() => {
                        this.isFormDetailCardActive = false
                    })
                else
                    this.isFormDetailCardActive = false

            },
            openFormDetailCard(formCategory, selectedFormData = null, isSubtype = false, tabIndex = 0) {
                this.selectedFormCategory = formCategory
                this.selectedFormData = { ...selectedFormData }
                this.selectedFormIsSubtype = isSubtype
                this.selectedTabIndex = tabIndex
                this.isFormDetailCardActive = true
                this.formHasChanged = false
            },
            hasSubtypes(formCategory) {
                return !!(Object.prototype.hasOwnProperty.call(formCategory, 'subtypes') && formCategory.subtypes.length)
            },
            toggle(table, row) {
                if (this.$refs[table] && this.$refs[table][0])
                    this.$refs[table][0].toggleDetails(row)
            },
            isOpen(table, row) {
                if (this.$refs[table] && this.$refs[table][0])
                    return this.$refs[table][0].isVisibleDetailRow(row)
            },
            rowClass(row) {
                if (
                    ![
                        'AC',
                        'AR',
                        'BD',
                        'CBCC',
                        'CC',
                        'CCL',
                        'CTC',
                        'CS',
                        'DRS',
                        'DS',
                        'DUC',
                        'ED',
                        'FOC',
                        'HA',
                        'HHR',
                        'IE',
                        'IGI',
                        'JD',
                        'RA',
                        'RT',
                        'SA',
                        'SD',
                        'SC',
                        'STC',
                        'TC',
                        'TP',
                        'TMD',
                        'TTC'
                    ].includes(row.type)
                )
                    return 'coming-soon'
            },
            getReminderClassList(row) {
                return {
                    'reminder-icon': true,
                    'icon-button': true,
                    'hidden': !(row.reminders && row.reminders.length > 0)
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "@/assets/vars.scss";

    .form-group + .form-group {
        margin-top: 16px;
    }

    .reminder-icon {
        margin-right: 0.5rem;
        display: inline;

        &.hidden {
            visibility: hidden;
        }

        .reminder-icon-info {
            position: absolute;
            min-width: 20rem;
            padding: .25rem 1rem;
            text-align: left;
            background: $grey-lighter;
            border: 1px solid $main-grey;
            z-index: 10;
            top: 2.45rem;
            right: 1.666rem;
            line-height: 1.5rem;
            opacity: 0;
            display: block;
            transition: 200ms opacity;
            font-size: .9rem;
            filter: drop-shadow(0 0 .25rem $main-grey);
            pointer-events: none;

            &:after {
                content: "";
                display: block;
                width: 1rem;
                height: 1rem;
                position: absolute;
                right: 1rem;
                top: -.575rem;
                transform: rotate(45deg);
                background: $grey-lighter;
                border-top: 1px solid $main-grey;
                border-left: 1px solid $main-grey;
            }
        }

        &:hover {
            .reminder-icon-info {
                opacity: 1;
            }
        }
    }

    .form-list {
        h2 {
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0.02em;
            padding: 40px 40px 40px 74px;
            background-color: $lightest-grey;
        }

        &__padding {
            background: $white;
            margin-top: 2px;
            padding: 32px 40px;
        }

        ::v-deep {
            .b-table {
                padding-bottom: 4rem;
                border-collapse: collapse;

                .table {
                    .action {
                        padding-left: 0;
                        padding-right: 0;
                        width: 5rem;
                    }

                    thead {
                        display: none;

                        tr {

                            th {
                                border: none;
                            }
                        }
                    }

                    tbody tr {
                        cursor: pointer;

                        td {
                            position: relative;
                            border-top-width: 1px;
                            border-top-color: $light-grey;
                            border-bottom: none;
                            height: 55px;
                            vertical-align: middle;
                            color: $black;

                            .icon {

                                &.toggle {
                                    position: absolute;
                                    left: 0;
                                    cursor: pointer;
                                    top: 50%;
                                    transform: translate(0px, -50%);
                                }
                            }
                        }

                        &:first-child {
                            td {
                                border-top-width: 0;
                            }
                        }

                        &.detail {
                            td {
                                border-color: greenyellow;
                            }
                        }

                        &.detail {
                            background: transparent;

                            &:hover {
                                background: transparent;
                                font-weight: initial;

                                a,
                                div {
                                    font-weight: normal;
                                }
                            }

                            td {
                                border-top-width: 1px;
                                border-color: $white;
                                padding: 0;

                                .detail-container {
                                    padding: 0;
                                }

                                ol {
                                    list-style: none;
                                    padding: 0;
                                    border-left: 1px solid $black;
                                    margin-left: 32px;
                                    padding-left: 22px;

                                    li {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;
                                        padding: 12px;
                                        height: 55px;
                                        border: solid $light-grey;
                                        border-width: 0 0 1px;
                                        position: relative;

                                        &:last-child {
                                            border: 0;
                                        }

                                        &:hover {
                                            background-color: #e9e6e1;

                                            a,
                                            div {
                                                font-weight: bold;
                                            }
                                        }

                                        .icon:hover {

                                            i {
                                                color: initial;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
