<template>
    <section>
        <b-field v-for="(meta, key, index) in fields" :key="index" :label="meta.label">
            <component :is="meta.component" v-model="form[key]" v-bind="meta.attrs" />
        </b-field>
    </section>
</template>

<script>
    // The `fields` prop Object should have following structure:
    // { property_name: { label: 'Property Name', component: 'b-input' } }
    // Only use this component if needed and no side effects. Fields like dropdowns are not supported yet.

    export default {
        name: 'GenericForm',
        props: { value: { type: Object, required: true }, fields: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
