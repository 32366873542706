<template>
    <div>
        <section>
            <b-field :label="$t('Recipe Name')" expanded>
                <b-input v-model="form.recipe_name" name="recipe_name" />
            </b-field>
            <b-field :label="$t('Amounts to portions')" expanded>
                <b-input v-model="form.amount_of_portions" name="amount_of_portions" />
            </b-field>
            <b-field grouped>
                <b-field :label="$t('Preparation')" expanded>
                    <b-input v-model="form.preparation" name="preparation" />
                </b-field>
                <b-field :label="$t('How to cook')" expanded>
                    <b-input v-model="form.making" name="making" />
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field :label="$t('Comment')" expanded>
                    <b-input v-model="form.comment" name="comment" />
                </b-field>
                <b-field :label="$t('Allergens in recipe')" expanded>
                    <b-input v-model="form.contains_allergens" name="contains_allergens" />
                </b-field>
            </b-field>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Amount')" class="column padding">
                    <b-input v-model="entry.amount" name="amount" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Ingredient')" class="column padding">
                    <b-input v-model="entry.ingredient" name="ingredient" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                            :title="$t('Delete entry')">
                        <DeleteIcon @click="removeEntry(index)" />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'RecipeTemplate',
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
