<template>
    <section class="multirow-form">
        <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
             class="columns is-variable is-1">
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Date and Time')" class="column padding">
                <g-datetimepicker v-model="entry.date" name="date" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Topic')" class="column padding">
                <b-input v-model="entry.topic" name="topic" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Instructor')" class="column padding">
                <b-input v-model="entry.instructor" name="instructor" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Duration')" class="column padding">
                <b-input v-model="entry.duration" name="duration" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Participant')" class="column padding">
                <b-autocomplete v-model="entry.participant" name="participant" :data="employeeData"
                                open-on-focus />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Participant Signature')" class="column padding">
                <b-input v-model="entry.participant_signature" name="participant_signature" />
            </b-field>
            <div class="column is-narrow">
                <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                        :title="$t('Delete entry')" @click="removeEntry(index)">
                    <b-icon icon="delete" size="default" />
                </button>
            </div>
        </div>
        <b-button class="is-info" icon-left="plus" @click="addEntryRow">
            {{ $t('Add entry row') }}
        </b-button>
    </section>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import { mapState } from 'vuex'

    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            this.date = null
            Object.assign(this, data)
        }
    }

    export default {
        name: 'AttendanceConfirmation',
        props: { value: { type: Object, required: true } },
        computed: {
            ...mapState('settings', [ 'employee' ]),
            employeeData(){
                return this.employee.data.map(d => d.lastname_and_firstname)
            },
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    else
                        model.entries.forEach ((entry) => {
                            // datetimepicker expects a date. if no date is set (since everything is optional),
                            // do not parse it (so nothing is displayed in the input field)
                            if (entry.date !== null && entry.date !== '')
                                entry.date = new Date(entry.date)

                        })

                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            this.$store.dispatch('settings/fetchEmployee')
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
