<template>
    <div>
        <section>
            <b-field :label="('Location')" class="column no-horizontal-padding">
                <b-input v-model="form.storage_location" name="storage_location"
                         :readonly="!!form.related_name"
                         :size="!!form.related_name ? 'is-static' : ''" />
            </b-field>
        </section>
        <section class="multirow-form margin-top">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-multiline is-variable">
                <hr class="hr column is-full is-paddingless">
                <div class="column columns is-full">
                    <b-field :label="$t('Date')" class="column padding">
                        <g-datepicker v-model="entry.date" name="date" />
                    </b-field>
                    <div class="column is-narrow">
                        <button class="button is-small is-inverted is-danger"
                                style="margin-bottom: .35em"
                                :title="$t('Delete entry')">
                            <DeleteIcon @click="removeEntry(index)" />
                        </button>
                    </div>
                </div>
                <storage-check-question v-model="form" :index="index"
                                        property-name="no_open_packages"
                                        :title="$t('No open packages and measurements')" />
                <storage-check-question v-model="form" :index="index"
                                        property-name="correctly_labeled"
                                        :title="$t('everything correctly labeled and measurements')" />
                <storage-check-question v-model="form" :index="index"
                                        property-name="nothing_expired"
                                        :title="$t('nothing expired and measurements')" />
                <storage-check-question v-model="form" :index="index"
                                        property-name="nothing_on_the_floor"
                                        :title="$t('nothing on the floor and measurements')" />
                <storage-check-question v-model="form" :index="index"
                                        property-name="fifo_principle_adhered_to"
                                        :title="$t('fifo principle and measurements')" />
                <storage-check-question v-model="form" :index="index" property-name="no_pests"
                                        :title="$t('no pests and measurements')" />
                <storage-check-question v-model="form" :index="index"
                                        property-name="clean_and_tidied"
                                        :title="$t('clean and tidy and measurements')" />
                <div
                    class="columns column is-half is-multiline margin-left-less is-paddingless margin-top-less">
                    <b-field :label="$t('signature')" class="column is-full is-paddingless" />
                    <b-input v-model="entry.signature" name="signature"
                             class="column is-marginless" />
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'
    import StorageCheckQuestion from '../components/StorageCheckQuestion'

    class Entry {
        constructor(data = {}) {
            const defaults = {
                $uuid: uuidv4(),
                no_open_packages: undefined,
                correctly_labeled: undefined,
                nothing_expired: undefined,
                nothing_on_the_floor: undefined,
                fifo_principle_adhered_to: undefined,
                no_pests: undefined,
                clean_and_tidied: undefined
            }
            Object.assign(this, defaults, data)
        }
    }

    export default {
        name: 'StorageCheck',
        components: { DeleteIcon, StorageCheckQuestion },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            if (this.form.related_name)
                this.form.storage_location = this.form.related_name
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "~@/assets/vars";

.no-horizontal-padding {
    padding: 0;
}

.margin-top {
    margin-top: 2rem;
}

.hr {
    border-top: 1px solid $pinkish-grey;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.margin-left-less {
    margin-left: 0;
}

.margin-right-less {
    margin-right: 0;
}

.margin-top-less {
    margin-top: 0;
}
</style>
