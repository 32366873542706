<template>
    <section class="multirow-form">
        <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
             class="columns is-variable is-1">
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Date')" class="column padding">
                <g-datepicker v-model="entry.date" name="date" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Product')" class="column padding">
                <b-input v-model="entry.product" name="product" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Supplier')" class="column padding">
                <g-autocomplete
                    v-model="entry.supplier"
                    :data="suppliersList"
                    open-on-focus
                    style="min-width: 8em;"
                />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Temperature') + ' [ºC]'" class="column padding">
                <b-input v-model="entry.temperature" name="temperature" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Quantity') + ' [kg]'" class="column padding">
                <b-input v-model="entry.quantity" name="quantity" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Quality')" class="column padding">
                <b-input v-model="entry.quality" name="quality" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Declaration')" class="column padding">
                <b-input v-model="entry.declaration" name="declaration" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Measure in the event of a shortage')"
                     class="column padding">
                <b-input v-model="entry.shortage_action" name="shortage_action" />
            </b-field>
            <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                     :label="$t('Signature')" class="column padding">
                <b-input v-model="entry.signature" name="signature" />
            </b-field>
            <div class="column is-narrow">
                <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                        :title="$t('Delete entry')">
                    <DeleteIcon @click="removeEntry(index)" />
                </button>
            </div>
        </div>
        <b-button class="is-info" icon-left="plus" @click="addEntryRow">
            {{ $t('Add entry row') }}
        </b-button>
    </section>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'
    import { mapState } from 'vuex'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'IncomingGoodsInspection',
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        data() {
            return { suppliersList: [] }
        },
        computed: {
            ...mapState('settings', [ 'supplier' ]),
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            supplier({ data }) {
                this.suppliersList = data.map(d => d.supplier)
            }
        },
        mounted() {
            this.$store.dispatch('settings/fetchSupplier')
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
