<template>
    <div class="columns column is-multiline">
        <b-field class="column" :label="$t('control')">
            <b-input v-model="form.entries[index].control" name="control" />
        </b-field>
        <b-field class="column" :label="$t('findings')">
            <b-input v-model="form.entries[index].findings" name="findings" />
        </b-field>
        <b-field class="column" :label="$t('actions')">
            <b-input v-model="form.entries[index].actions" name="actions" />
        </b-field>
        <b-field class="column" :label="$t('responsible')">
            <g-autocomplete v-model="form.entries[index].responsible"
                            name="responsible"
                            :data="employees"
                            open-on-focus
            />
        </b-field>
        <b-field class="column" :label="$t('to be done until')">
            <g-datepicker v-model="form.entries[index].to_be_done_until" name="to_be_done_until" />
        </b-field>
        <b-field class="column" :label="$t('done at')">
            <g-datepicker v-model="form.entries[index].done_at" name="done_at" />
        </b-field>
    </div>
</template>

<script>
    export default {
        name: 'InfrastructureExaminationEntry',
        props: {
            value: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            employees:{
                type: Array,
                required: true
            }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
}
</style>

