<template>
    <div>
        <section>
            <b-field grouped>
                <b-field :label="$t('Subject')" expanded>
                    <b-input v-model="form.subject" name="subject" />
                </b-field>
                <b-field :label="$t('Goal')" expanded>
                    <b-input v-model="form.goal" name="goal" />
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field :label="$t('Duration')" expanded>
                    <b-input v-model="form.duration" name="duration" />
                </b-field>
                <b-field :label="$t('Date, Time')" expanded>
                    <g-datetimepicker v-model="form.date_and_time" name="date_and_time" />
                </b-field>
            </b-field>
            <b-field grouped>
                <b-field :label="$t('Location')" expanded>
                    <b-input v-model="form.location" name="location" />
                </b-field>
                <b-field :label="$t('Attendees')" expanded>
                    <b-input v-model="form.attendees" name="attendees" />
                </b-field>
            </b-field>
            <b-field :label="$t('Review of trainging content')" expanded>
                <b-input v-model="form.review_of_training_content" name="review_of_training_content" />
            </b-field>
        </section>
        <hr>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Time')" class="column padding">
                    <b-input v-model="entry.time" name="time" />
                </b-field>
                <b-input v-model="entry.structure" type="hidden" name="structure" />
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Structure')" class="column padding">
                    <input :value="entry.structure_long"
                           class="input"
                           style="border: 0;"
                           type="text"
                           readonly="true">
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Tools')" class="column padding">
                    <b-input v-model="entry.tools" name="tools" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Content')" class="column padding">
                    <b-input v-model="entry.content" name="content" />
                </b-field>
            </div>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'TrainingPreparation',
        components: { },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    const options = [
                        { value: 'EN', text: this.$t('Entry') },
                        { value: 'PK', text: this.$t('Pickup prior knowledge') },
                        { value: 'MP', text: this.$t('Main Part') },
                        { value: 'C', text: this.$t('Closing') }
                    ]
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry(), new Entry() ]

                    // We always want the same order and amount of trainging structure
                    model.entries[0].structure = options[0].value
                    model.entries[0].structure_long = options[0].text
                    model.entries[1].structure = options[1].value
                    model.entries[1].structure_long = options[1].text
                    model.entries[2].structure = options[2].value
                    model.entries[2].structure_long = options[2].text
                    model.entries[3].structure = options[3].value
                    model.entries[3].structure_long = options[3].text
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
