<template>
    <section>
        <div class="field">
            <b-checkbox v-model="active">
                {{ $t('Activate Reminder function') }}
            </b-checkbox>
        </div>

        <ValidationObserver v-slot="{ handleSubmit }" ref="observer" tag="div">
            <form :disabled="!active" novalidate @submit.prevent>
                <div class="columns">
                    <ValidationProvider v-slot="{ errors, touched, validated, invalid }" slim
                                        name="time"
                                        :rules="active ? 'required' : undefined">
                        <b-field :label="$t('Notification should be sent at (hour):')"
                                 :message="errors"
                                 :type="((touched||validated) && invalid) ? 'is-danger' : ''"
                                 class="column is-marginless">
                            <g-timepicker v-model="form.time" name="time" required />
                        </b-field>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{ errors, touched, validated, invalid }"
                                        name="start_date"
                                        :rules="active ? 'required' : undefined">
                        <b-field :label="$t('Starting from (date):')" class="column is-marginless"
                                 :message="errors.start_date"
                                 :type="((touched||validated) && invalid) ? 'is-danger' : ''">
                            <g-datepicker v-model="form.start_date" name="start_date" required />
                        </b-field>
                    </ValidationProvider>
                </div>
                <div class="columns">
                    <div class="field column is-marginless">
                        <div class="columns is-paddingless">
                            <span class="column is-narrow is-marginless"
                                  style="display: flex;flex-direction: column;justify-content: center;"
                            >{{ $t('Repeat every') }}</span>
                            <ValidationProvider v-slot="{ errors, touched, validated, invalid }"
                                                name="interval"
                                                slim :rules="active ? 'required' : undefined">
                                <b-field class="column is-narrow is-marginless" :message="errors"
                                         :type="((touched||validated) && invalid) ? 'is-danger' : ''"
                                         style="padding-left:0; padding-right: 0;">
                                    <b-input v-model="form.interval" type="number" min="1"
                                             max="99" />
                                </b-field>
                            </ValidationProvider>
                            <ValidationProvider v-slot="{ errors, touched, validated, invalid }"
                                                name="frequency"
                                                slim :rules="active ? 'required' : undefined">
                                <b-field class="column is-marginless" :message="errors"
                                         :type="((touched||validated) && invalid) ? 'is-danger' : ''">
                                    <b-select v-model="form.frequency">
                                        <option v-for="option in frequencies" :key="option.key"
                                                :value="option.key">
                                            {{ option.label }}
                                        </option>
                                    </b-select>
                                </b-field>
                            </ValidationProvider>
                        </div>
                    </div>
                </div>
                <ValidationProvider v-slot="{ errors, touched, validated, invalid }"
                                    name="employees"
                                    slim :rules="active ? 'required' : undefined">
                    <b-field :label="$t('Send notification to:')" :message="errors"
                             :type="((touched||validated) && invalid) ? 'is-danger' : ''">
                        <b-taginput
                            ref="employeeQuery"
                            v-model="form.employees"
                            class="autocomplete--v2"
                            :data="filteredEmployees"
                            :allow-new="false"
                            :allow-duplicates="false"
                            field="lastname_and_firstname"
                            autocomplete
                            icon="label"
                            attached
                            :open-on-focus="true"
                            :placeholder="$t('Select an employee')"
                            @input="resetFilteredEmployees"
                            @focus="getFilteredEmployees()"
                            @typing="getFilteredEmployees" />
                    </b-field>
                </ValidationProvider>
            </form>

            <portal to="dialog-buttons">
                <div class="buttons is-centered">
                    <b-button type="is-primary"
                              @click="handleSubmit(save)">
                        {{ $t('Save') }}
                    </b-button>
                </div>
            </portal>

            <b-message v-if="wasSentToday" size="is-small" style="margin-top: 1rem">
                <b>{{ $t('Note') }}</b> {{ $t('The reminder was already sent today.') }}
            </b-message>
        </ValidationObserver>
    </section>
</template>

<script>
    import { RRule, rrulestr } from 'rrule'
    import dayjs from 'dayjs'
    import { NotificationMixin } from '@/config/mixins'
    import ApiService from '@/services/api.service'
    import cloneDeep from 'lodash/cloneDeep'
    import isObject from 'lodash/isObject'
    import { arrayize } from '@/utils'

    export default {
        name: 'FormReminders',
        mixins: [ NotificationMixin ],
        props: { formType: { type: String, required: true } },
        data() {
            return {
                form: {
                    date: null,
                    start_date: new Date(),
                    time: '09:00',
                    frequency: 'weekly',
                    interval: 1,
                    employees: []
                },
                initialFormState: null,
                active: false,
                wasSentToday: false,

                // options
                frequencies: [
                    { key: 'daily', label: this.$t('day(s)') },
                    { key: 'weekly', label: this.$t('week(s)') },
                    { key: 'monthly', label: this.$t('month(s)') },
                    { key: 'yearly', label: this.$t('year(s)') }
                ],
                employees: [],
                filteredEmployees: []
            }
        },
        computed: {
            rrule() {
                const dayOfWeek = {
                    0: RRule.MO,
                    1: RRule.TU,
                    2: RRule.WE,
                    3: RRule.TH,
                    4: RRule.FR,
                    5: RRule.SA,
                    6: RRule.SU
                }[ dayjs(this.form.start_date).weekday() ]

                const [ hour, minute ] = this.form.time.split(':').map(i => parseInt(i))
                const start = dayjs(this.form.start_date)
                    .startOf('day').set('h', hour).set('m', minute)

                return new RRule({
                    freq: RRule[ this.form.frequency.toUpperCase() ],
                    interval: this.form.interval,
                    byweekday: [ dayOfWeek ],
                    dtstart: start.toDate()
                })
            }
        },
        watch: {
            active(newValue, oldValue) {
                if (oldValue === true && newValue === false)
                    this.resetAndDelete()
            }
            // 'form.time'(value) {
            //     delete this.errors.time
            //     if (!value)
            //         this.errors.time = this.$t('Invalid value')
            // }
        },
        async mounted() {
            this.employees = (await ApiService.get('settings/employee/')).data
            this.resetFilteredEmployees()
            await this.fetchReminderConfig()
        },
        methods: {
            getFilteredEmployees(text) {
                this.filteredEmployees = this.employees
                    .filter(employee => text ? employee.lastname_and_firstname.toLowerCase().indexOf(text.toLowerCase()) >= 0 : true)
                    .filter(employee => employee.email && employee.email.length > 0)
                    .filter(employee => !this.form.employees.map(e => e.id).includes(employee.id))
            },
            async fetchReminderConfig() {
                const response = await this.$store.dispatch('reminders/fetch', this.formType)
                this.active = false
                if (response.status === 200)
                    if (response.data && response.data.recurrences) {
                        const record = response.data
                        const rrule = rrulestr(record.recurrences)
                        this.active = true
                        this.wasSentToday = response.data.was_sent_today
                        Object.assign(this.form, {
                            id: null,
                            start_date: rrule.options.dtstart,
                            interval: rrule.options.interval,
                            frequency: [ 'yearly', 'monthly', 'weekly', 'daily' ][ rrule.options.freq ],
                            time: dayjs(rrule.options.dtstart).format('HH:mm')
                        }, record)
                        Object.assign(this.form, { employees: record.employees.map(id => this.employees.find(employee => employee.id === id)) })
                    } else {
                        Object.assign(this.form, response.data)
                    }
                else if (response.status === 206)
                    this.form = {}
                else
                    throw new Error(`[getCurrentForm] unhandled ${ response.status } response from server.`)
                this.initialFormState = cloneDeep(this.form)
            },
            async save() {
                const record = {
                    id: this.form.id,
                    form_type: this.formType,
                    recurrences: this.rrule.toString(),
                    employees: this.form.employees.map(employee => employee.id)
                }

                // delete the record
                if (this.active === false)
                    return this.resetAndDelete()

                // save the record
                try {
                    await this.$store.dispatch('reminders/save', record)
                    this.notifySuccess()
                    this.fetchReminderConfig()
                } catch (e) {
                    if (e.response && isObject(e.response.data)) {
                        const data = e.response.data
                        this.$refs.observer.setErrors(data)
                        // show non-field errors
                        if (data.__all__)
                            arrayize(data.__all__).forEach((msg) => this.notifyError(msg))
                    } else {
                        this.notifyError(e.response ? e.response.data || e.response : e)
                        throw new Error(`[reminders] unhandled ${ e.response.status } response from server.`)
                    }
                }
            },
            resetFilteredEmployees() {
                this.filteredEmployees = this.employees.filter(employee => employee.email)
            },
            async resetAndDelete() {
                await this.$store.dispatch('reminders/delete', { form_type: this.formType })
                this.notifySuccess()
                this.fetchReminderConfig()
                this.wasSentToday = false
                this.form.id = null
                this.form.time = '9:00'
                this.form.start_date = dayjs().toDate()
            }
        }
    }
</script>
