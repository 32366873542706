<template>
    <div id="form-detail-card" class="card">
        <header class="card-header">
            <button
                type="button"
                class="modal-close is-large"
                style="position:absolute; top: 2px; right: 4px;"
                @click="$parent.close" />
            <p class="card-header-title is-centered">
                {{ formCategory.label }}
            </p>
            <a href="#" class="card-header-icon" aria-label="more options">
                <span class="icon">
                    <i class="fas fa-angle-down" aria-hidden="true" />
                </span>
            </a>
        </header>
        <div class="card-content">
            <div class="content">
                <b-tabs v-model="selectedTab" type="is-boxed" expanded>
                    <b-tab-item>
                        <template slot="header">
                            <EditIcon class="is-hidden-mobile" />
                            {{ $t('Edit') }}
                        </template>
                        <form @submit.prevent>
                            <component :is="currentFormComponentName" v-if="currentForm"
                                       ref="currentFormComponent" v-model="currentForm"
                                       :subtype="formIsSubtype" />
                            <date-or-version v-if="currentForm" v-model="currentForm" />
                        </form>
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <ReminderIcon class="is-hidden-mobile" />
                            {{ $t('Reminder') }}
                        </template>
                        <FormReminders v-if="selectedTab === 1"
                                       :form-type="currentFormComponentName" />
                    </b-tab-item>
                    <b-tab-item>
                        <template slot="header">
                            <DownloadIcon class="is-hidden-mobile" />
                            {{ $t('Download') }}
                        </template>
                        <div class="has-text-centered download-box">
                            <DocumentDownloadIcon />
                            <div style="margin-top: 30px;">
                                {{ $t('Here you can download the empty form.') }}
                            </div>
                        </div>
                        <br>
                        <div class="has-text-centered">
                            <a class="btn-link" target="_blank"
                               :href="formCategory.pdf">

                                <DownloadIcon class="is-hidden-mobile mr-2" />
                                <span>{{ $t('Download document') }}</span>
                            </a>
                        </div>
                    </b-tab-item>
                </b-tabs>
                <portal-target v-if="selectedTab === 1" name="dialog-buttons" slim />
                <div v-else class="buttons is-centered"
                     :style="{visibility: selectedTab === 0 ? 'visible' : 'hidden'}">
                    <b-button type="is-primary" @click="saveCurrentForm()">
                        {{ $t('Save') }}
                    </b-button>

                    <b-button id="archive-button" type="is-primary"
                              :disabled="isLoading"
                              @click="saveAndArchiveCurrentForm()">
                        {{ $t('Save & Archive') }}
                    </b-button>
                </div>
            </div>
        </div>
        <ConfirmEmptyFormArchival ref="confirmEmptyFormArchival" />
    </div>
</template>

<script>
    import cloneDeep from 'lodash/cloneDeep'
    import isEqual from 'lodash/isEqual'

    import formComponents from '../formcomponents'
    import ApiService from '@/services/api.service'
    import { NotificationMixin } from '@/config/mixins'

    import EditIcon from '@/components/icons/Edit'
    import ReminderIcon from '@/components/icons/Reminder'
    import DownloadIcon from '@/components/icons/Download'
    import DocumentDownloadIcon from '@/components/icons/DocumentDownload'
    import DateOrVersion from './DateOrVersion'
    import FormReminders from '@/modules/Reminders/components/FormReminders'
    import ConfirmEmptyFormArchival from './ConfirmEmptyFormArchival.modal'
    import { removeEmptyProperties } from '@/utils'
    import isEmpty from 'lodash/isEmpty'

    export default {
        name: 'FormDetailCard',
        components: {
            DateOrVersion,
            EditIcon,
            ReminderIcon,
            DocumentDownloadIcon,
            DownloadIcon,
            ...formComponents,
            FormReminders,
            ConfirmEmptyFormArchival
        },
        mixins: [ NotificationMixin ],
        props: {
            formIsSubtype: {
                type: Boolean,
                default: false
            },
            formCategory: {
                type: Object,
                required: true
            },
            formData: {
                type: Object,
                default: null
            },
            selectedTabIndex: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {
                initialFormState: cloneDeep(this.formData),
                currentForm: this.formData,
                initialPositioningDone: false,
                tabItemElement: null,
                selectedTab: this.selectedTabIndex,
                isLoading: false
            }
        },
        computed: {
            currentFormComponentName() {
                return this.formCategory.type
            },
            currentFormComponent() {
                return this.$refs.currentFormComponent
            },
            hasChanged() {
                return !isEqual(this.currentForm, this.initialFormState)
            }
        },
        watch: {
            hasChanged(value) {
                this.$emit('change', value)
            }
        },
        destroyed() {
            if (this.tabItemElement)
                this.tabItemElement = null
            Object.keys(this.currentForm).forEach(key => {
                delete this.currentForm[ key ]
            })
        },
        methods: {
            async getCurrentForm() {
                const response = await ApiService.get(`forms/categories/${ this.formCategory.type }/`)
                if (response.status === 200)
                    this.currentForm = response.data || {}
                else if (response.status === 206)
                    (() => {
                    })() // noop
                else
                    throw new Error(`[getCurrentForm] unhandled ${ response.status } response from server.`)

                this.initialFormState = cloneDeep(this.currentForm)
            },
            async saveCurrentForm() {
                if (this.currentFormComponent.presave !== undefined)
                    this.currentForm.send_notifications = await this.currentFormComponent.presave({ action: 'save' })
                return this.saveForm()
            },
            async saveForm() {
                delete this.currentForm.company

                const data = this.currentFormComponent && this.currentFormComponent.prepareDataForBackend
                    ? this.currentFormComponent.prepareDataForBackend(this.currentForm)
                    : this.currentForm

                try {
                    if (this.currentForm.id)
                        await ApiService.put(`forms/categories/${ this.formCategory.type }/`, data)
                    else
                        await ApiService.post(`forms/categories/${ this.formCategory.type }/new/`, data)

                    this.notifySuccess()
                    this.$emit('success')
                } catch (e) {
                    this.notifyError(e.response ? e.response.data || e.response : e)
                }
            },
            async saveAndArchiveCurrentForm() {
                if (this.currentFormComponent.presave !== undefined)
                    this.currentForm.send_notifications = await this.currentFormComponent.presave({ action: 'saveAndArchive' })
                if (!this.hasChanged && this.isEmpty() && !(await this.$refs.confirmEmptyFormArchival.show()))
                    // if the second condition returns false (cancel), then stop
                    return
                return this.saveAndArchiveForm()
            },
            async saveAndArchiveForm() {

                delete this.currentForm.company
                this.isLoading = true
                try {
                    if (this.currentForm.id)
                        await ApiService.put(`forms/categories/${ this.formCategory.type }/`, this.currentForm)
                    else {
                        await ApiService.post(`forms/categories/${ this.formCategory.type }/new/`, this.currentForm)
                        await this.getCurrentForm()
                    }
                    await ApiService.post(`archive/archive/`, { form: this.currentForm.id })
                    this.notifySuccess(this.$i18n.t('Successfully archived form.'))
                    this.$emit('success')
                    this.isLoading = false
                } catch (err) {
                    this.isLoading = false
                    this.notifyError(err.response.data)
                }
            },
            isEmpty() {
                const filteredForm = removeEmptyProperties(this.currentForm, { ignoreKeys: [ 'DELETE', 'id', '$uuid' ] })
                return isEmpty(filteredForm)
            }
        }
    }
</script>

<style lang="scss">
@import "~@/assets/vars";

.tabs {
    li {
        a svg {
            margin-right: 1rem;
        }

        &.is-active {
            a svg {
                g, path {
                    fill: $primary;
                }
            }
        }
    }
}

.download-box {
    border: 1px dashed $pinkish-grey;
    border-radius: 4px;
    padding: 40px;
    font-size: 1.1rem;
    max-width: 400px;
    margin: 10vh auto 0;
}
</style>
