<template>
    <div>
        <section>
            <b-field :label="$t('FryingOilCheck_Title_Description')">
                <b-input v-model="form.description" name="description" :readonly="!!form.related_name" :size="!!form.related_name ? 'is-static' : ''" />
            </b-field>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Date')" class="column padding">
                    <g-datepicker v-model="entry.date" name="date" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Test Result')" class="column padding">
                    <b-input v-model="entry.test_result" name="test_result" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Has Filtered Oil')" class="column padding">
                    <b-switch v-model="entry.has_filtered_oil" name="has_filtered_oil" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Has Cleaned Fryer')" class="column padding">
                    <b-switch v-model="entry.has_cleaned_fryer" name="has_cleaned_fryer" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Comment')" class="column padding">
                    <b-input v-model="entry.comment" name="comment" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Signature')" class="column padding">
                    <b-input v-model="entry.signature" name="signature" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                            :title="$t('Delete entry')">
                        <DeleteIcon @click="removeEntry(index)" />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'

    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'FryingOilCheck',
        components: { DeleteIcon },
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        mounted() {
            if(this.form.related_name)
                this.form.description = this.form.related_name
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
