<template>
    <generic-form v-model="form" :fields="fields" />
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'

    export default {
        name: 'DamageSuspicion',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return { fields: { phone: { label: this.$t('Phone'), component: 'b-input' } } }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
