<template>
    <div>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Name and first name')" class="column padding">
                    <b-input v-model="entry.name_person" name="name_person" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Reason for entering')" class="column padding">
                    <b-input v-model="entry.reason" name="reason" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Date and time')" class="column padding">
                    <g-datetimepicker v-model="entry.date_and_time" name="date_and_time" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Signature Visitor')" class="column padding">
                    <b-input v-model="entry.signature_visitor" name="signature_visitor" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Signature person responsible')" class="column padding">
                    <b-input v-model="entry.signature_responsible_person" name="signature_responsible_person" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger" style="margin-bottom: .35em"
                            :title="$t('Delete entry')" @click="removeEntry(index)">
                        <b-icon icon="delete" size="default" />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'

    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'AccessRegulations',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    else
                        model.entries.forEach ((entry) => {
                            // datetimepicker expects a date. if no date is set (since everything is optional),
                            // do not parse it (so nothing is displayed in the input field)
                            if (entry.date_and_time !== null && entry.date_and_time !== '')
                                entry.date_and_time = new Date(entry.date_and_time)

                        })

                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry({ 'date_and_time': null }))
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
