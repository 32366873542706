<template>
    <section>
        <b-message has-icon type="is-info">
            {{ $t('settings.messages.edit') }}
            <router-link :to="settingsLink">
                {{ $t('settings.messages.page') }}
            </router-link>
            .
        </b-message>
        <b-table :data="form.entries">
            <template slot-scope="props">
                <b-table-column field="food_group" :label="$t('Food group')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.food_group }}
                </b-table-column>

                <b-table-column field="used_food" :label="$t('For which food')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.used_food }}
                </b-table-column>

                <b-table-column field="color" :label="$t('Color')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.color }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    export default {
        name: 'CuttingBoardColorConcept',
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                settingsLink: {
                    name: 'settings.list',
                    hash: '#cutting_board_color_concept'
                }
            }
        },
        computed: {
            form: {
                get() {
                    return this.value || {}
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
