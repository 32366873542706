<template>
    <generic-form v-model="form" :fields="fields" />
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'
    import { mapState } from 'vuex'

    export default {
        name: 'SupplierAgreement',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return {
                fields: {
                    supplier: {
                        label: this.$t('Supplier'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    recipient: {
                        label: this.$t('Recipient'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    products: {
                        label: this.$t('Products'),
                        component: 'b-input',
                        attrs: { type: 'textarea' }
                    },
                    latest_entry: { label: this.$t('Latest entry'), component: 'b-input' },
                    delivery: { label: this.$t('Delivery'), component: 'b-input' },
                    min_quantity: { label: this.$t('Minimum quantity'), component: 'b-input' },
                    order_medium: {
                        label: this.$t('Order medium'), component: 'g-select', attrs: {
                            expanded: true,
                            data: [
                                { label: this.$t('Phone'), value: 'PH' },
                                { label: this.$t('Email'), value: 'EM' },
                                { label: this.$t('Web Shop'), value: 'WS' },
                                { label: this.$t('Fax'), value: 'FA' },
                                { label: this.$t('Other'), value: 'OT' }
                            ]
                        }
                    },
                    order_detail: {
                        label: this.$t('Order detail'),
                        component: 'b-input',
                        attrs: { type: 'textarea' }
                    }
                }
            }
        },
        computed: {
            ...mapState('administration', [ 'businesses' ]),
            ...mapState('settings', [ 'supplier' ]),
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            businesses(value) {
                this.fields.recipient.attrs.data = value.items.map(d => d.name)
            },
            supplier(value) {
                this.fields.supplier.attrs.data = value.data.map(d => d.supplier)
            }
        },
        mounted() {
            this.$store.dispatch('administration/fetchBusinesses')
            this.$store.dispatch('settings/fetchSupplier')
        }
    }
</script>
