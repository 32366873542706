<template>
    <div class="cleaning-schedule-form">
        <b-message v-if="subtype"
                   type="is-info message-like">
            <BulbIcon />
            <i18n v-if="subtype"
                  tag="div"
                  path="cleaning-schedule.info">
                <router-link :to="{name: 'settings.list', hash: '#cleaning_location'}">
                    {{ $t('settings-link') }}
                </router-link>
            </i18n>
        </b-message>
        <section>
            <b-field :label="$t('Location or Post')" expanded>
                <b-input v-model="form.location_post" name="location_post" :disabled="subtype" />
            </b-field>
            <b-field :label="$t('from')" expanded>
                <g-datepicker v-model="form.cleaning_schedule_from" name="cleaning_schedule_from" />
            </b-field>
            <b-field :label="$t('to')" expanded>
                <g-datepicker v-model="form.cleaning_schedule_to" name="cleaning_schedule_to" />
            </b-field>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('What')" class="column padding">
                    <g-autocomplete v-if="!subtype"
                                    v-model="entry.what"
                                    name="target"
                                    :data="targetList"
                                    open-on-focus
                                    @change="updateRelatedFields(entry)"
                    />
                    <b-input v-else
                             v-model="entry.what"
                             name="target"
                             :title="entry.what"
                             :readonly="true"
                    />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Cleaning Supply')" class="column padding">
                    <g-autocomplete v-if="!subtype"
                                    v-model="entry.cleaning_supply"
                                    name="product"
                                    :data="cleaningAgentList"
                                    open-on-focus
                    />
                    <b-input v-else
                             v-model="entry.cleaning_supply"
                             name="product"
                             :title="entry.cleaning_supply"
                             :readonly="true"
                    />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Cleaning Frequency')"
                         class="column padding small">
                    <b-input v-model="entry.frequency" name="frequency" :readonly="subtype" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Monday')" class="column padding small">
                    <b-input v-model="entry.monday" name="monday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Tuesday')" class="column padding small">
                    <b-input v-model="entry.tuesday" name="tuesday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Wednesday')" class="column padding small">
                    <b-input v-model="entry.wednesday" name="wednesday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Thursday')" class="column padding small">
                    <b-input v-model="entry.thursday" name="thursday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Friday')" class="column padding small">
                    <b-input v-model="entry.friday" name="friday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Saturday')" class="column padding small">
                    <b-input v-model="entry.saturday" name="saturday" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Sunday')" class="column padding small">
                    <b-input v-model="entry.sunday" name="sunday" />
                </b-field>
                <div class="column is-narrow">
                    <button v-if="!subtype"
                            class="button is-small is-inverted is-danger"
                            style="margin-bottom: .35em"
                            :title="$t('Delete entry')">
                        <DeleteIcon @click="removeEntry(index)" />
                    </button>
                </div>
            </div>
            <b-button v-if="!subtype" class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'
    import { mapState } from 'vuex'
    import { cloneDeep } from 'lodash'
    import { getIntervalLabelByKey } from '../../Settings/const'
    import BulbIcon from '@/components/icons/Bulb'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'CleaningSchedule',
        components: { DeleteIcon, BulbIcon },
        props: {
            value: {
                type: Object,
                required: true
            },
            subtype: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            form: {
                get() {
                    const model = this.value || {}

                    if (this.subtype)
                        model.entries = this.convergePreset(model)
                    else if (!model.entries || !model.entries.length)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]

                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
            ...mapState('settings', [ 'inventory', 'cleaningAgent', 'cleaningLocation' ]),
            targetList() {
                return this.inventory.data.map(d => d.name)
            },
            cleaningAgentList() {
                return this.cleaningAgent.data.map(d => d.product)
            }
        },
        mounted() {
            if (this.form.related_name)
                this.form.location_post = this.form.related_name
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            },
            updateRelatedFields(entry) {
                const inventoryItem = this.inventory.data.find(
                    el => el.name === entry.what
                )

                if (inventoryItem) {
                    if (!entry.frequency)
                        entry.frequency = getIntervalLabelByKey(inventoryItem.cleaning_interval)
                    if (!entry.cleaning_supply)
                        entry.cleaning_supply = inventoryItem.cleaning_agent
                }
                this.$forceUpdate()
            },
            convergePreset(model) {
                const presetId = JSON.parse(model.is_subtype_by_field).id
                const preset = cloneDeep(this.cleaningLocation.data.find(item => item.id === presetId))

                if(preset.entries && preset.entries.length) {
                    for (const [ index, inventoryId ] of preset.entries.entries()) {
                        const inventoryItem = this.inventory.data.find(item => item.id === inventoryId)

                        if (inventoryItem) {
                            const saved = model.entries.find(entry => entry.what === inventoryItem.name) || {}
                            const entry = {
                                what: inventoryItem.name,
                                cleaning_supply: inventoryItem.cleaning_agent,
                                frequency: getIntervalLabelByKey(inventoryItem.cleaning_interval)
                            }

                            preset.entries[index] = { ...saved, ...entry }
                        }
                    }

                    return preset.entries
                }

                return model.entries
            }
        }
    }
</script>
