<template>
    <section>
        <i18n tag="b-message" path="supplier-directory.info" has-icon type="is-info">
            <router-link :to="{name: 'settings.list', hash:'#supplier'}">{{ $t('settings-link') }}</router-link>
        </i18n>
        <b-table
            :data="form.entries"
        >
            <template slot-scope="props">
                <b-table-column field="food_groups" :label="$t('Food Groups')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.food_groups }}
                </b-table-column>

                <b-table-column field="supplier" :label="$t('Supplier')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.supplier }}
                </b-table-column>

                <b-table-column field="order_type" :label="$t('Order Type')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.order_type }}
                </b-table-column>

                <b-table-column field="contact_person" :label="$t('Contact Person')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.contact_person }}
                </b-table-column>

                <b-table-column field="customer_number" :label="$t('Cust. Number')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.customer_number }}
                </b-table-column>

                <b-table-column field="delivery_date" :label="$t('Delivery Date')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.delivery_date }}
                </b-table-column>

                <b-table-column field="description" :label="$t('SupplierDirectory_Description')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.description }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    import { uuidv4 } from '@/utils'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'SupplierDirectory',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
