<template>
    <div>
        <b-message has-icon type="is-info">{{ $t('risk-assessement.info') }}</b-message>
        <generic-form v-model="form" :fields="fields" />
    </div>
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'

    export default {
        name: 'RiskAssessment',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return {
                fields: {
                    has_children: { label: this.$t('Has children'), component: 'b-switch' },
                    has_seniors: { label: this.$t('Has seniors'), component: 'b-switch' },
                    has_pregnant_women: { label: this.$t('Has pregnant women'), component: 'b-switch' },
                    has_weakened_people: { label: this.$t('Has weakened people'), component: 'b-switch' },

                    has_raw_meat_dishes: { label: this.$t('Has raw meat dishes'), component: 'b-switch' },
                    has_poultry_dishes: { label: this.$t('Has raw poultry dishes'), component: 'b-switch' },
                    has_sea_products_dishes: { label: this.$t('Has raw sea food dishes'), component: 'b-switch' },
                    has_egg_dishes: { label: this.$t('Has raw eggs dishes'), component: 'b-switch' }
                }
            }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
