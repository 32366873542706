<template>
    <section>
        <i18n tag="b-message" path="employee-directory.info" has-icon type="is-info">
            <router-link :to="{name: 'settings.list', hash: '#employee'}">{{ $t('settings-link') }}</router-link>
        </i18n>
        <b-table
            :data="form.entries"
        >
            <template slot-scope="props">
                <b-table-column field="lastname_and_firstname" :label="$t('Last Name and First Name')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.lastname_and_firstname }}
                </b-table-column>

                <b-table-column field="function" :label="$t('Function')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.function }}
                </b-table-column>

                <b-table-column field="email" :label="$t('Email Address')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.email }}
                </b-table-column>

                <b-table-column field="phone" :label="$t('Phone Number')">
                    <template slot="header" slot-scope="{ column }">
                        {{ $t(column.label) }}
                    </template>
                    {{ props.row.phone }}
                </b-table-column>
            </template>
        </b-table>
    </section>
</template>

<script>
    export default {
        name: 'EmployeeDirectory',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    return this.value || {}
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
