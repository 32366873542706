<template>
    <section>
        <b-field :label="$t('Date/Version')">
            <b-input v-model="form.date_or_version" name="date_or_version" />
        </b-field>
    </section>
</template>

<script>
    export default {
        name: 'DateOrVersion',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
section {
    margin: 2rem 0;
}
</style>
