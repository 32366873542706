// Register all your form components here
// Note: Make sure all your components have the `name` property. In case of forms,
// this should be a camelcased version of the backened enum label
// ('Business Description' becomes 'BusinessDescription')

import AC from './AttendanceConfirmation'
import AR from './AccessRegulations'
import BD from './BusinessDescription'
import CC from './CleaningConcept'
import CS from './CleaningSchedule'
import CTC from './CoreTemperatureCheck'
import CBCC from './CuttingBoardColorConcept'
import CCL from './CateringChecklist'
import DS from './DamageSuspicion'
import DRS from './DiseaseReportSheet'
import DUC from './DisposalUnitCheck'
import ED from './EmployeeDirectory'
import FOC from './FryingOilCheck'
import HA from './HazardAnalysis'
import HHR from './HygieneHealthRules'
import IE from './InfrastructureExamination'
import IGI from './IncomingGoodsInspection'
import JD from './JobDescription'
import RA from './RiskAssessment'
import RT from './RecipeTemplate'
import SA from './SupplierAgreement'
import SC from './StorageCheck'
import SD from './SupplierDirectory'
import STC from './StorageTemperatureCheck'
import TMD from './TestAndMeasuringEquipmentDirectory'
import TC from './TrainingConcept'
import TP from './TrainingPreparation'
import TTC from './TransportTemperatureCheck'

export default {
    AC,
    AR,
    BD,
    CBCC,
    CC,
    CCL,
    CTC,
    CS,
    DUC,
    DRS,
    DS,
    ED,
    FOC,
    HA,
    HHR,
    IE,
    IGI,
    JD,
    RA,
    RT,
    SA,
    SC,
    SD,
    STC,
    TMD,
    TC,
    TP,
    TTC
}
