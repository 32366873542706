<template>
    <generic-form v-model="form" :fields="fields" />
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'
    import { mapState } from 'vuex'

    export default {
        name: 'BusinessDescription',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return {
                fields: {
                    name_and_address: {
                        label: this.$t('Name and address'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    phone: {
                        label: this.$t('Phone'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    email: {
                        label: this.$t('Email'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    fax: { label: this.$t('Fax'), component: 'b-input' },
                    owner: { label: this.$t('Owner'), component: 'b-input' },
                    tenant: { label: this.$t('Tenant'), component: 'b-input' },
                    responsible_person: {
                        label: this.$t('Responsible Person'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    replacement_responsible_person: {
                        label: this.$t('Replacement responsible person'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    type_and_size_of_business: { label: this.$t('Type and Size of business'), component: 'b-input' },
                    offering_and_specificities: { label: this.$t('Offering and specificities'), component: 'b-input' },
                    restaurant_supervision: {
                        label: this.$t('Restaurant supervision'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    employee_executive_apprentice_count: { label: this.$t('Employee executive apprentice count'), component: 'b-input' },
                    business_category: {
                        label: this.$t('Business category'),
                        component: 'g-autocomplete',
                        attrs: {
                            data: [],
                            openOnFocus: true
                        }
                    },
                    serves_catering_for_sensitive_population: { label: this.$t('Serves catering for sensitive population'), component: 'b-switch' },
                    cooks_high_risk_dishes: { label: this.$t('Cooks high risk dishes'), component: 'b-switch' }
                }
            }
        },
        computed: {
            ...mapState('administration', [ 'businesses' ]),
            ...mapState('settings', [ 'employee' ]),
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            businesses({ items }) {
                const namesWithAddress = items.map(d => `${d.name} - ${d.address}`)
                const phoneNumbers = items.map(d => d.telephone)
                const categories = items.map(d => d.category)
                const emails = items.map(d => d.email)

                this.fields.email.attrs.data = emails
                this.fields.phone.attrs.data = phoneNumbers
                this.fields.name_and_address.attrs.data = namesWithAddress
                this.fields.business_category.attrs.data = categories
            },
            employee({ data }) {
                const names = data.map(d => d.lastname_and_firstname)
                this.fields.responsible_person.attrs.data = names
                this.fields.replacement_responsible_person.attrs.data = names
                this.fields.restaurant_supervision.attrs.data = names
            }
        },
        mounted() {
            this.$store.dispatch('administration/fetchBusinesses')
            this.$store.dispatch('settings/fetchEmployee')
        }
    }
</script>
