<template>
    <div
        class="columns column is-half is-multiline margin-right-less margin-left-less margin-top-less">
        <b-field :label="title" class="column is-full is-paddingless" />
        <b-field class="column is-narrow is-marginless is-paddingless" style="align-items: center">
            <b-radio-button
                :value="form.entries[ index ][ propertyName ] === true ? true : undefined"
                :native-value="true"
                type="is-success"
                @click.native.stop.prevent="toggle(true)">
                <b-icon icon="check" />
            </b-radio-button>
            <b-radio-button
                :value="form.entries[ index ][ propertyName ] === false ? false : undefined"
                :native-value="false"
                type="is-danger"
                @click.native.stop.prevent="toggle(false)">
                <b-icon icon="close" />
            </b-radio-button>
        </b-field>
        <b-input v-model="form.entries[index][measure_prop]" :name="measure_prop"
                 class="column is-marginless" />
    </div>
</template>

<script>
    export default {
        name: 'StorageCheckQuestion',
        props: {
            title: { type: String, required: true },
            value: { type: Object, required: true },
            propertyName: { type: String, required: true },
            index: { type: Number, required: true }
        },
        computed: {
            measure_prop: function () {
                return this.propertyName + '_measure'
            },
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            toggle(value) {
                if (this.form.entries[ this.index ][ this.propertyName ] === value)
                    this.form.entries[ this.index ][ this.propertyName ] = undefined
                else
                    this.form.entries[ this.index ][ this.propertyName ] = value
                this.$forceUpdate()
                window.blur()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .columns /deep/ .b-radio.button {
        font-size: .9rem;
        color: #666;
        &.is-focused {
            border-color: transparent !important;
        }
        > span {
            font-weight: normal;
        }
        &.is-success, &.is-danger {
            color: white;
        }
        .icon {
            margin: 0 -.5rem;
        }
    }
</style>
