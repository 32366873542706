<template>
    <div>
        <section>
            <b-field grouped>
                <b-field :label="$t('Picked up by')" expanded>
                    <b-input v-model="form.picked_up_by" name="picked_up_by" />
                </b-field>
                <b-field :label="$t('Storage place')" expanded>
                    <b-input v-model="form.storage_place" name="storage_place" :disabled="subtype" />
                </b-field>
            </b-field>
            <div class="columns">
                <div class="column is-6">
                    <b-field :label="$t('Pickup on')" expanded>
                        <g-datepicker v-model="form.pickup_on" name="pickup_on" />
                    </b-field>
                </div>
                <div class="column is-6">
                    <b-field :label="$t('Goal temperature')" expanded>
                        <b-input v-model="form.goal_temperature" name="goal_temperature" />
                    </b-field>
                </div>
            </div>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Date')" class="column padding">
                    <g-datepicker v-model="entry.date" name="date" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Actual temperature')"
                         class="column padding">
                    <b-input v-model="entry.actual_temperature" name="actual_temperature" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Order and cleanliness')"
                         class="column padding">
                    <b-input v-model="entry.order_and_cleanliness" name="order_and_cleanliness" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Picked up')" class="column padding">
                    <b-switch v-model="entry.picked_up" name="picked_up" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'" :label="$t('Signature')" class="column padding">
                    <b-input v-model="entry.signature" name="signature" />
                </b-field>
                <div class="column is-narrow">
                    <button class="button is-small is-inverted is-danger"
                            style="margin-bottom: .35em"
                            :title="$t('Delete entry')">
                        <DeleteIcon @click="removeEntry(index)" />
                    </button>
                </div>
            </div>
            <b-button class="is-info" icon-left="plus" @click="addEntryRow">
                {{ $t('Add entry row') }}
            </b-button>
        </section>
    </div>
</template>>

<script>
    import { uuidv4 } from '@/utils'
    import DeleteIcon from '@/components/icons/Delete'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'DisposalUnitCheck',
        components: { DeleteIcon },
        props: {
            value: { type: Object, required: true },
            subtype: { type: Boolean, required: true }
        },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
