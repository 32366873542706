<template>
    <div>
        <i18n tag="b-message" path="test-and-measuring-directory.info" has-icon type="is-info">
            <router-link :to="{name: 'settings.list', hash:'#test_and_measuring_device'}">{{ $t('settings-link') }}</router-link>
        </i18n>
        <section>
            <b-field grouped>
                <b-field :label="$t('Test Intervall')" expanded>
                    <b-input v-model="form.test_intervall" name="test_intervall" />
                </b-field>
            </b-field>
        </section>
        <section class="multirow-form">
            <div v-for="(entry, index) in form.entries" :key="entry.$uuid"
                 class="columns is-variable is-1">
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Date')" class="column padding">
                    <g-datepicker v-model="entry.date" name="date" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Description / Serialnumber of Device')" class="column padding">
                    <b-input v-model="entry.description_serialno_device" readonly disabled type="text" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Tolerance of Device')" class="column padding">
                    <b-input v-model="entry.tolerance" name="tolerance" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Reference value')" class="column padding">
                    <b-input v-model="entry.reference_value" name="reference_value" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('1st Measurement')" class="column padding">
                    <b-input v-model="entry.measurement_value1" name="measurement_value1" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('2nd Measurement')" class="column padding">
                    <b-input v-model="entry.measurement_value2" name="measurement_value2" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Action')" class="column padding">
                    <b-input v-model="entry.action" name="action" />
                </b-field>
                <b-field :custom-class="index === 0 ? '' : 'is-hidden-desktop'"
                         :label="$t('Signature')" class="column padding">
                    <b-input v-model="entry.signature" name="signature" />
                </b-field>
            </div>
        </section>
    </div>
</template>

<script>
    import { uuidv4 } from '@/utils'


    class Entry {
        constructor(data = {}) {
            this.$uuid = data.$uuid || uuidv4()
            Object.assign(this, data)
        }
    }

    export default {
        name: 'TestAndMeasuringEquipmentDirectory',
        props: { value: { type: Object, required: true } },
        computed: {
            form: {
                get() {
                    const model = this.value || {}
                    if (!model.entries)
                        model.entries = [ new Entry(), new Entry(), new Entry() ]
                    return model
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        },
        methods: {
            addEntryRow() {
                this.form.entries.push(new Entry())
                this.$forceUpdate()
            },
            removeEntry(entryIndex) {
                this.form.entries.splice(entryIndex, 1)
                this.$forceUpdate()
            }
        }
    }
</script>
