<template>
    <generic-form v-model="form" :fields="fields" />
</template>

<script>
    import GenericForm from '../components/GenericForm.vue'

    export default {
        name: 'CateringChecklist',
        components: { GenericForm },
        props: { value: { type: Object, required: true } },
        data() {
            return {
                fields: {
                    occasion: { label: this.$t('Occasion'), component: 'b-input' },
                    date: { label: this.$t('Date'), component: 'g-datepicker' },
                    contact_person_phone: { label: this.$t('Contact person phone'), component: 'b-input' },
                    place_and_street: { label: this.$t('Place and street'), component: 'b-input' },
                    person_count: { label: this.$t('Person count'), component: 'b-input' },
                    kids_vegi_allergics: { label: this.$t('Kids, veggies, allergics'), component: 'b-input' },
                    time: { label: this.$t('Time'), component: 'b-input' },
                    responsible_person: { label: this.$t('Responsible person'), component: 'b-input' },
                    places_for_food_processing: { label: this.$t('Places for food processing'), component: 'b-input' },
                    water_connection_or_measure: { label: this.$t('Water connection or measure'), component: 'b-input' },
                    cooling_possible_or_measure: { label: this.$t('Cooling possible or measure'), component: 'b-input' },
                    cooking_possible_or_measure: { label: this.$t('Cooking possible or measure'), component: 'b-input' },
                    food_processing_possible_or_measure: { label: this.$t('Food processing possible or measure'), component: 'b-input' },
                    electric_connection_or_measure: { label: this.$t('Electric connection or measure'), component: 'b-input' },
                    freezing_possible_or_measure: { label: this.$t('Freezing possible or measure'), component: 'b-input' },
                    tables_available_or_measure: { label: this.$t('Tables available or measure'), component: 'b-input' },
                    offering_without_cooling: { label: this.$t('Offering without cooling'), component: 'b-input' },
                    offering_with_cooling: { label: this.$t('Offering with cooling'), component: 'b-input' },
                    offering_with_freezing: { label: this.$t('Offering with freezing'), component: 'b-input' },
                    offering_impure: { label: this.$t('Offering impure'), component: 'b-input' },
                    origin_fish_and_meat: { label: this.$t('Origin fish / meat'), component: 'b-input' },
                    allergens: { label: this.$t('Allergens'), component: 'b-input' },
                    containers_are_clean: { label: this.$t('Containers are clean'), component: 'g-nullable-boolean' },
                    vehicle_is_clean: { label: this.$t('Vehicle is clean'), component: 'g-nullable-boolean' },
                    transport_cooling_temperature: { label: this.$t('Transport cooling temperature'), component: 'b-input' },
                    transport_freezing_temperature: { label: this.$t('Transport freezing temperature'), component: 'b-input' }
                }
            }
        },
        computed: {
            form: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            }
        }
    }
</script>
